import * as React from 'react'
import loadable from 'loadable-components'
import { Redirect } from 'react-router-dom'

export default [
  {
    path: '/',
    exact: true,
    // 因为要部署在二级目录下，所以 / 路径需要重新跳转到 /react 作为真正的根目录
    component: () => <Redirect to="/react" />,
    meta: { isPrivate: true }
  },
  {
    path: '/react',
    component: loadable(() => import('../views/Home')),
    meta: { isPrivate: true }
  },
  {
    path: '/import_order/:step?',
    component: loadable(() => import('../views/ImportOrder')),
    meta: { isPrivate: true, hideFooter: true }
  },
  {
    path: '/cart',
    component: loadable(() => import('../views/Order/Cart')),
    meta: { isPrivate: true, hideFooter: true }
  },
  {
    path: '/pay/:bid',
    component: loadable(() => import('../views/Order/Pay')),
    meta: { isPrivate: true }
  },
  {
    path: '/login',
    component: loadable(() => import('../views/Login')),
    meta: { isPrivate: false }
  },
  {
    path: '/sku',
    component: loadable(() => import('../views/ImportOrder/sku_search')),
    meta: { isPrivate: true }
  },
  {
    path: '/cpm',
    component: loadable(() => import('../views/Cpm')),
    meta: { isPrivate: true }
  },
  {
    path: '/luggageService',
    component: loadable(() => import('../views/Order/LuggageService')),
    meta: { isPrivate: true }
  },
  {
    path: '/order/:step?',
    component: loadable(() => import('../views/Order')),
    routes: [
      {
        path: '/order/0',
        component: loadable(() => import('../views/Order/Services'))
      },
      {
        path: '/order/1',
        component: loadable(() => import('../views/Order/ReturnService')),
        meta: { isPrivate: true }
      },
      {
        path: '/order/2',
        component: loadable(() => import('../views/Order/Ordering')),
        meta: { isPrivate: true }
      },
      {
        path: '/order/3',
        component: loadable(() => import('../views/Order/Confirm')),
        meta: { isPrivate: true }
      },
      {
        path: '/order/returnService/0',
        component: loadable(() => import('../views/Order/ReturnService')),
        meta: { isPrivate: true }
      },
      {
        path: '/order/returnService/1',
        component: loadable(() => import('../views/Order/Ordering')),
        meta: { isPrivate: true }
      },
      {
        path: '*',
        component: ({ location }) => <Redirect to={{ ...location, pathname: '/order/0' }} />
      }
    ]
  },
  {
    path: '/orderIframeOnly',
    component: loadable(() => import('../views/Order/OrderIframeOnly')),
    meta: {}
  },
  require('./accountCenter').default,
  {
    exact: true,
    path: '/mall',
    component: loadable(() => import('../views/Mall')),
    meta: { isPrivate: false }
  },
  {
    exact: true,
    path: '/mall/cart',
    component: loadable(() => import('../views/Mall/Cart')),
    meta: { isPrivate: true }
  },
  {
    path: '/mall/:goodsId/detail',
    component: loadable(() => import('../views/Mall/Detail')),
    meta: { isPrivate: false }
  },
  {
    path: '*',
    // 必须放在最后，匹配不到路由的时候才会跳转到 /login 了
    component: () => {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = '/404'
        return <div>正在跳转..</div>
      } else {
        alert('正式环境下会跳转到 /404')
        return <Redirect to="/" />
      }
    }
  }
]
