import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { getQuerystring } from '../utils'

const lngs = {
  zh: require('./zh').default,
  en: require('./en').default,
  de: require('./de').default,
}

const config = {
  fallbackLng: 'zh',
  debug: false,
  defaultNS: 'common',
  ns: [],
  resources: {},
}

Object.keys(lngs).forEach((lng: string) => {
  const { ns, resources } = config
  const locale = lngs[lng]

  ns.push(...Object.keys(locale))
  config.ns = [...new Set(ns)]
  resources[lng] = lngs[lng]
})

i18n.use(LanguageDetector).init(config)

setTimeout(() => {
  const lang = $.cookie('lang')

  if (getQuerystring().lng) {
    i18n.changeLanguage(getQuerystring().lng)
  } else if (lang) {
    i18n.changeLanguage(lang)
  }

  document.querySelector('html').setAttribute('lang', i18n.language)
}, 0)
;(window as any).i18n = i18n
export default i18n
