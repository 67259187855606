import { observable } from 'mobx'

import api from '../api'

export default class Billing {
  root: any

  constructor(root) {
    this.root = root
  }

  @observable
  banner: any = null

  @observable
  categories: any = []

  @observable
  products: any = []

  @observable
  orders: any = {
    data: [],
    paginator: {}
  }

  @observable
  goodsList: any[] = []

  @observable
  related_products: any[] = []

  @observable
  curGoods: any = null

  @observable
  cartModel = {
    goodsList: [],
    receiverAddress: {},
    fee: {}
  }

  async fetchBanner() {
    const res = await api.fetchMallBanner()
    this.banner = res
    return res
  }

  async fetchCategorise() {
    const res = await api.fetchMallCategories()
    this.categories = res
    return res
  }

  async fetchProducts(params) {
    const { data } = await api.fetchMallProducts({ ...params })
    this.products = data
    return data
  }

  async fetchOrders(params) {
    const res = await api.fetchMallOrders({ ...params })
    this.orders = res
    return res
  }

  async fetchRelatedProducts(sku) {
    const { data } = await api.fetchRelatedProducts({ sku: sku })
    this.related_products = data
    return data
  }

  async fetchGoodsList() {
    const categories = await this.fetchCategorise()

    this.goodsList = categories.map(cateItem => ({
      ...cateItem,
      goodsItems: []
    }))

    // 并发获取各分类的商品
    await Promise.all(
      this.goodsList.map(async listItem => {
        const goodsItems = await this.fetchProducts({ category_id: listItem.id })
        listItem.goodsItems = goodsItems
        return goodsItems
      })
    )

    return this.goodsList
  }

  async fetchGoodsById(goodsId) {
    const [curGoods] = await this.fetchProducts({ SKU: goodsId })
    this.curGoods = curGoods
    return curGoods
  }

  async addCart(sku: string, qty?: number) {
    return api.CartAction({
      sku: sku,
      qty: qty,
      action: 'add'
    })
  }

  async removeCart(sku: string) {
    return api.CartAction({
      sku: sku,
      action: 'remove'
    })
  }

  async CartSubmit(addr_id, note) {
    return api.CartSubmit({
      address_id: addr_id,
      note: note
    })
  }

  async fetchCartList() {
    const { data, fee } = await api.fetchMallCartItems()
    this.cartModel.goodsList = data
    this.cartModel.fee = fee
  }
}
