module.exports = {
	"accountInformation": "My account",
	"orders": "My orders",
	"lables": "Parcel labels",
	"accountBalance": "Account Balance",
	"myLoginDetails": "User information",
	"bussinessAccount": "Business customer",
	"companyInformation": "Company Information",
	"downloadInvoiceFaPiao": "My invoices",
	"commercialDeclaration": "Customs declaration",
	"myLuggage": "Luggage storage",
	"luggageInformation": "Luggage information",
	"discount": "Discount",
	"myRewards": "My rewards",
	"myCoupon": "My Coupon",
	"giftMoney": "Gift Money",
	"others": "Others tools",
	"declaration": "Product declaration",
	"addressBook": "Address book",
	"siteMessage": "Site message",
	"preferenceSetting": "Preference setting",
	"internationalParcels": "International parcel orders",
	"ukParcels": "UK parcel orders",
	"balanceToPay": "Balance To Pay",
	"packagingOrder": "Packaging order",
	"collectionInHongkong": "Collection in Hongkong",
	"recipient": "Recipient",
	"recipientsContactNumber": "Recipient's contact number",
	"orderNumber": "Order number",
	"services": "Services",
	"time": "Time",
	"search": "Search",
	"combinedLabels": "Combined labels",
	"dataExport": "Data Export",
	"trackingStatus": "Tracking status",
	"all": "All",
	"waitingForCollection": "Waiting for collection",
	"collected": "Collected",
	"processed": "Processed",
	"deliveryInChina": "Delivery in China",
	"cancelled": "Cancelled",
	"ordersWithLabelCreationFailed": "Orders with label creation failed",
	"ordersAwaitingForRecipientIdUpload": "Orders awaiting for Recipient ID upload",
	"serviceFWLX": "Service",
	"serviceLuxian": "Service",
	"sender": "Sender",
	"destination": "Destination",
	"weightDeclared": "Weight declared",
	"shippingFee": "Shipping fee",
	"status": "Status",
	"action": "Action",
	"parcelNumber": "Order Number",
	"declaredStatus": "Status",
	"parcelDeclared": "Customs declaration",
	"certificate": "Certificate",
	"orderNumber/trackingNumber": "Order number/Tracking number",
	"ukTrackingNumber": "UK Tracking number",
	"labelToDownload": "Label to download",
	"orderDetails": "Order details",
	"duplicateOrder": "Duplicate order",
	"orderNumberDing": "Order Number",
	"trackingNumber": "Tracking number",
	"serviceName": "Service",
	"collectionTime": "Collection Time",
	"quantity": "Quantity",
	"name": "Name",
	"telephone": "Telephone",
	"orderStatus": "Order Status",
	"orderDate": "Order date",
	"payDate": "Pay Date",
	"orderNumberIntl": "Order Number",
	"feeCategory": "Fee category",
	"amount": "Amount",
	"note": "Note",
	"packagingProducts": "Packaging products",
	"postCode": "Post Code",
	"orderInformation": "Order information",
	"orderValue": "Order value",
	"courierService": "Courier Service",
	"trackingNumberPaiSong": "Tracking number",
	"applyForSelfCollction": "Apply for self collction",
	"parcelArrived": "Parcel Arrived",
	"waitingForCollectionTiHuo": "Waiting for collection",
	"collectionCompleted": "Collection completed",
	"parcelWeight": "Parcel Weight",
	"inboundDate": "Inbound date",
	"storagePeriod": "Storage period",
	"7DaysFreeStorage,5CnyPerDayAfter,SettleAtCollection": "7 days free storage, 5 CNY per day after, settle at collection",
	"unprinted": "unprinted",
	"printed": "printed",
	"markAsPrinted": "Mark as printed",
	"topupInformation": "Topup information",
	"transactionRecord": "transaction record",
	"accountBalanceMuQian": "Account Balance",
	"rewardsEarned": "Rewards earned",
	"topupAmount": "Topup amount",
	"otherAmount": "Other amount",
	"topUp": "Top up",
	"today": "Today",
	"thisWeek": "This week",
	"thisMonth": "This month",
	"thisYear": "This year",
	"date": "Date",
	"amountYuE": "Amount Balance",
	"paymentMethod": "Payment method",
	"transactionType": "Transaction type",
	"noteXiaoFei": "Note",
	"country": "Country",
	"userName": "User name",
	"emailAddress": "Email address",
	"cityChengShi": "City",
	"qqNumber": "QQ Number",
	"wechatId": "Wechat ID",
	"password": "Password",
	"confirmPassword": "Confirm password",
	"emailSubscription": "Email subscription",
	"subscribed": "Subscribed",
	"save": "Save",
	"accountType": "Account type",
	"companyName": "Company name",
	"vat/EoriNumber": "VAT / EORI number",
	"contactName": "Contact name",
	"contactNumber": "Contact number",
	"address1": "Address 1",
	"address2": "Address 2",
	"address3": "Address 3",
	"business": "Business",
	"individual": "Individual",
	"invoiceNumber": "Invoice number",
	"dateCreated": "Date",
	"downloadInvoice": "Download invoice",
	"downloadExportCertificate": "Download export certificate",
	"combineInvoices": "Combine invoices",
	"combineExportCertificate": "Combine export certificate",
	"downloadClick": "Download",
	"returnLuggage": "Return luggage",
	"myLuggageWode": "Luggage storage",
	"orderNumberJiCun": "Order number",
	"collectionDate": "Collection Date",
	"outboundDate": "Outbound date",
	"storageFee": "Storage fee",
	"submit": "Submit",
	"couponsAvailable": "Coupons available",
	"priceList": "Price list",
	"couponsCanUse": "Coupons to be redeemed",
	"couponsToBeRedeemed": "Coupons to be redeemed",
	"availableLuckyMoney": "Available lucky money",
	"expiredLuckyMoney": "Expired lucky money",
	"declarationForm": "New declaration",
	"myDeclaration": "My Declaration",
	"serviceSelected": "Service selected",
	"countryOfOrigin": "Country of origin",
	"brandEn": "Brand",
	"itemDescription": "Item description",
	"brandInChinese": "Brand in Chinese",
	"putN/aIfNoChineseBrandName": "Put N/A if no Chinese brand name",
	"itemDescriptionInChinese": "Item Description in Chinese",
	"productCategory": "Product Category",
	"itemPrice": "Item price",
	"specification": "Specification",
	"unit": "Unit",
	"netWeight(g)": "Net weight (g)",
	"itemLink": "Online purchase link",
	"receipt": "Receipt",
	"uploadPic": "Upload",
	"reset": "Reset",
	"itemDescriptionInEnglish/Chinese": "Item description in English / Chinese",
	"brand": "Brand",
	"price": "Price",
	"net/GrossWeight": "Net / Gross weight",
	"recipient'sAddress": "Recipient's address",
	"addRecipient'sAddress": "Add recipient's address",
	"tel": "Mobile number",
	"province": "Province",
	"city": "City",
	"district": "District",
	"recipientIdNumber": "Chinese ID Card number",
	"default": "Set as default address",
	"sender'sAddress": "Sender's address",
	"address": "Address",
	"addSender'sAddress": "Add sender's address",
	"title": "Title",
	"messageType": "message type",
	"read": "Read",
	"yes": "Yes",
	"noNeed": "No",
	"oneOrderOneEmailNotifiation": "Email notification for every order",
	"oneEmailNotificationForOnePayment": "Email notification for every payment",
	"welcome": "Welcome",
	"sameParcel": "Same Parcel",
	"orderTrackAlert": "On this page, you can download parcel labels, check parcel order information, and track parcel status.",
	"orderTrackDescription": "Please click \"view labels\" to print the parcel label. The printed barcode should be clear for scan and attached on to the parcel firmly. You can send it to selected drop off points or to wait for the driver to collect.",
	"planDetails": "Plan Details",
	"setDefaultPaymentMethod": "Set default payment method",
	"emailNotification": "Email notification",
	"newsCenter": "News",
	"internationalPostalService": "International Postal Service",
	"laPoste": "La Poste",
	"royalMailSmallParcel": "Royal Mail Small Parcel",
	"bpost": "Bpost",
	"pf": "Parcel Force Small parcel",
	"PFGlobalDirect": "Parcel Force Global Direct",
	"PFGlobalPriority": "Parcel Force Global Priority",
	"4DDP": "4 Tin Milk DDP",
	"6DDP": "6 Tin Milk DDP",
	"333DDP": "333 Misc.Grocery Parcel DDP",
	"333plus": "333 Plus Misc.Grocery Parcel DDP",
	"luggagePostalService": "Luggage Postal Service",
	"upsExpress": "UPS International Documents Express Service",
	"skincareSmallParcelDDP": "Skincare Small Parcel DDP",
	"giftsetSmallParcelDDP": "Giftset Small Parcel DDP",
	"groceriesSmallParcelDDP": "Groceries Small Parcel DDP",
	"etk": "ETK",
	"milkDDP": "Milk DDP",
	"miscDDP": "Misc.Grocery Parcel DDP",
	"noData": "No Data",
	"collection": "Collection",
	"dropOff": "Drop off",
	"selfDropOff": "Drop of at depot",
	"ordersGaiKuang": "Orders",
	"seeAllOrders": "All Orders",
	"faq": "FAQ",
	"consultAndFaq": "Consult & FAQ",
	"myOrders": "My Orders",
	"shopOrders": "Shop Orders"
};