import { flow, observable } from 'mobx'
import { AddressType, HOSTS } from '../constants'
import api from '../api'

export default class User {
  root: any

  constructor(root) {
    this.root = root
  }

  @observable
  curLng: string = navigator.language.split('-')[0]

  @observable
  detail: any = {}

  @observable
  address = {}

  @observable
  isMilkUser: boolean = false

  @observable
  invoice: any = {}

  @observable
  senderAddress = []

  @observable
  receiverAddress = []

  @observable
  couponList = []

  @observable
  availableCouponList = []

  @observable
  redEnvelope = []

  @observable
  expired_redEnvelope = []

  @observable
  messageList: any = {
    data: [],
    paginator: {}
  }
  @observable
  messageDetail = {}

  @observable
  declareGoods: any = {
    data: [],
    paginator: {}
  }

  @observable
  affiliate_cash_log: any = {
    data: [],
    paginator: {}
  }

  @observable
  settings: any = {}

  get balance() {
    return HOSTS.current === 'uk' ? this.detail.gbp_balance : this.detail.eur_balance
  }

  get token() {
    return localStorage.getItem('jwttoken')
  }

  get keepLogined() {
    return localStorage.getItem('keepLogined') === 'true'
  }

  login = flow(function*(params: { username?: string; phone?: string; password: string }) {
    const res = yield api[params.username ? 'login' : 'phoneLogin'](params)

    localStorage.setItem('jwttoken', res.token)
    $.cookie('jwttoken', res.token, { expires: 1, path: '/', domain: 'send2china.co.uk' })
    $.cookie('jwttoken', res.token, { expires: 1, path: '/', domain: 'send2china.de' })
    return res
  })

  phoneLogin = flow(function*(params: { phone: string; password: string }) {
    const res = yield api.phoneLogin(params)

    localStorage.setItem('jwttoken', res.token)
    $.cookie('jwttoken', res.token, { expires: 1, path: '/', domain: 'send2china.co.uk' })
    $.cookie('jwttoken', res.token, { expires: 1, path: '/', domain: 'send2china.de' })
    return res
  })

  async logout(report?: boolean) {
    if (report) {
      await api.logout()
    }

    localStorage.removeItem('jwttoken')
    localStorage.removeItem('keepLogined')
    $.removeCookie('jwttoken', { path: '/', domain: 'send2china.co.uk' })
    $.removeCookie('jwttoken', { path: '/', domain: 'send2china.de' })
    this.detail = {}
  }

  async fetchDetail() {
    const res = await api.fetchUserDetail()

    this.detail = res

    // 同步客服相关信息
    const siteNameMap = {
      de: '德国',
      uk: '英国'
    }
    return res
  }

  async fetchIsMilkUser() {
    const { show } = await api.isMilkUser()
    this.isMilkUser = show
  }

  async fetchSettings() {
    const res = await api.fetchUserSettings()

    this.settings = res

    return res
  }

  fetchAddress(params: {
    ptype: AddressType
    q?: any
    page?: number
    receiver_type?: 'cn' | 'other'
  }) {
    return api.fetchAddress(params)
  }

  async saveAddress(params) {
    const res = await api.saveAddress({ country: HOSTS.current.toUpperCase(), ...params })

    return res
  }

  async updateUserInfo(params) {
    const res = await api.updateUserDetail({ ...params })
    return res
  }

  async fetchCoupon(params?) {
    //获取已经领取的优惠券
    const res = await api.fetchCoupon({ ...params })
    this.couponList = res
    return res
  }

  async fetchAvailableCoupon() {
    //获取可领取的优惠券列表
    const res = await api.fetchCouponList()
    this.availableCouponList = res
    return res
  }

  async receiveCoupon(coupon_id) {
    const res = await api.receiveCoupon({ coupon_id: coupon_id })
    return res
  }

  async fetchRedEnvelope() {
    const res = await api.fetchRedPacket()
    this.redEnvelope = res
    return res
  }
  async fetchExpiredRedEnvelope() {
    const res = await api.fetchRedPacket({ status: 'expired' })
    this.expired_redEnvelope = res
    return res
  }
  async fetchInvoiceInfo() {
    const res = await api.fetchUserInvoiceInfo()
    this.invoice = res
    return res
  }

  async updateInvoiceInfo(params) {
    const res = await api.updateUserInvoiceInfo({ ...params })
    return res
  }

  async fetchMessageList(params?) {
    const res = await api.fetchMessage({ ...params })
    this.messageList = res
    return res
  }

  async fetchMessageDetail(params) {
    const res = await api.fetchMessageDetail({ ...params })
    this.messageDetail = res.data
    return res
  }

  async fetchDeclareGoods(params) {
    const res = await api.fetchDeclareGoods({ ...params })
    this.declareGoods = res
    return res
  }

  async createDeclareGoods(params) {
    const res = await api.createDeclareGoods({ ...params })
    return res
  }

  async rechargeUser(params) {
    const res = await api.rechargeUser({ ...params })
    return res
  }

  async fetchAffiliateCashLog(params) {
    const res = await api.affiliateCashLog({ ...params })
    this.affiliate_cash_log = res
    return res
  }
}
