import {observable} from 'mobx'

import api from '../api'

export default class Address {
  root: any

  constructor(root) {
    this.root = root
  }

  @observable
  detail: any = {}

  @observable
  addresses: any = {
    data: [],
    paginator: {}
  }

  @observable
  senderAddress:any={
    data:[],
    paginator: {}
  }

  @observable
  receiverAddress:any={
    data:[],
    paginator: {}
  }
  async fetchAddress(params?: {ptype:string, page?: number,q?:string,pk?:number }) {
    const res = await api.fetchAddress(params)
    this.addresses = res
    return res
  }
  async fetchAddressDetail(params: { pk: number}) {
    const res = await api.fetchAddressDetail(params)
    this.detail = res.data
    return res.data
  }
  async fetchSenderAddress(params?: {page?: number,q?:string,pk?:number }) {
    params['ptype']='sender'
    const res = await api.fetchAddress(params)
    this.senderAddress = res
    return res
  }
  async fetchReceiverAddress(params?: {page?: number,q?:string,pk?:number }) {
    params['ptype']='receiver'
    const res = await api.fetchAddress(params)
    this.receiverAddress = res
    return res
  }

  async delAddress(params: { pk: number}) {
    return await api.delAddress(params)
  }
}
