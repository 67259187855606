import { observable } from 'mobx'

import api from '../api'

export default class Good {
  root: any

  constructor(root) {
    this.root = root
  }

  @observable
  goods: any = {
    data: [],
    paginator: {}
  }

  async searchServiceGood(intl_service_id: number, q: string, page: number) {
    this.goods = await api.fetchServiceGoods({ intlservice_id: intl_service_id, q: q, page: page })
  }
}
