import {observable} from 'mobx'

import api from '../api'

export default class Affiliate {
  root: any

  constructor(root) {
    this.root = root
  }

  @observable
  detail: any = {}

  @observable
  affiliates: any = {
    data: [],
    paginator: {}
  }
  @observable
  affiliate_logs: any = {
    data: [],
    paginator: {}
  }

  @observable
  promotion_event: any = {
    data: [],
    paginator: {}
  }

  async fetchDetail(params: { code: string }) {
    const res = await api.fetchAffiliate(params)
    this.detail = res
    return res
  }

  async fetchAffiliates(params?: { page?: number, perpage?: number, start_time?: string, end_time?: string }) {
    const res = await api.fetchAffiliate(params)
    this.affiliates = res
    return res
  }

  async fetchAffiliateLog(params?: { page?: number, perpage?: number, }) {
    const res = await api.fetchAffiliateLog(params)
    this.affiliate_logs = res
    return res
  }

  async fetchPromotionEvents(params?: { page?: number, perpage?: number, }) {
    const res = await api.fetchPromotionEvent(params)
    this.promotion_event = res
    return res
  }

  async JoinEvent(event_id) {
    const res = await api.joinPromotionEvent({event_id: event_id})
    return res
  }
}