import {observable} from 'mobx'

import api from '../api'

export default class Billing {
  root: any

  constructor(root) {
    this.root = root
  }

  @observable
  detail: any = {}

  @observable
  billings: any = {
    data: [],
    paginator: {}
  }
  @observable
  invoices:any={
    data:[],
    paginator: {}
  }
  async fetchBillings(params?: { page?: number, perpage?: number, start_time?: string, end_time?: string }) {
    const res = await api.fetchBillingList(params)
    this.billings = res
    return res
  }

  async fetchInvoices(params?: { page?: number, perpage?: number, start_time?: string, end_time?: string,number?:string }) {
    const res = await api.fetchInvoiceList(params)
    this.invoices = res
    return res
  }
  async MergeInvoicePdf(params) {
    return await api.mergeInvoicePdf(params)
  }
}
