module.exports = {
	"site": "Site",
	"shippingQuote": "Shipping Quote",
	"myAccount": "My Account",
	"cart": "Cart",
	"cpm": "CPM",
	"logout": "Logout",
	"home": "Home",
	"servicesAndProducts": "Services&Products",
	"findAService": "Find a service",
	"freightForwarding": "Freight Forwarding",
	"haitaoTransshipment": "Haitao Transshipment",
	"fulfillmentService": "Fulfillment Service",
	"promotions": "Promotions",
	"mall": "Mall",
	"packingMaterials": "Packing Materials",
	"8bella": "8bella Mall",
	"about": "About",
	"news": "News",
	"enterPackageNumber": "Enter package number",
	"tracking": "Tracking",
	"welcome": "Welcome",
	"newNotify": "New Messages",
	"systemNotice": "System notice",
	"csNotice": "Customer service notice",
	"switchCountry": "Location",
	"deStation": "Germany",
	"ukStation": "UK",
	"gbp": "GBP",
	"eur": "EUR",
	"addressbook": "AddressBook",
	"name": "Name",
	"phone": "Phone",
	"city": "City",
	"street1": "Street 1",
	"street2": "Street 2",
	"street3": "Street 3",
	"postcode": "Postcode",
	"house": "House",
	"street": "Street",
	"idnumber": "ID Number",
	"noData": "No data",
	"address": "Address",
	"chooseAddress": "Please select address"
};