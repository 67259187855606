import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import cloneDeep from 'lodash/cloneDeep'
import axios, { AxiosRequestConfig } from 'axios'
import { message, Modal } from 'antd'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import models from '../models'

// http config
const TIMEOUT_TIME = 30000
let progessTimer = null

axios.defaults.timeout = TIMEOUT_TIME
axios.defaults.withCredentials = true
axios.defaults.baseURL = '/api'
;(axios.defaults.transformRequest as any).push((data) => {
  clearTimeout(progessTimer)
  progessTimer = setTimeout(() => NProgress.start(), 300)
  return data
})
;(axios.defaults.transformResponse as any).push((data) => {
  clearTimeout(progessTimer)
  NProgress.done()
  return data
})

const clearData = (data) => {
  if (isArray(data)) {
    data.forEach(clearData)
  } else if (isObject(data)) {
    Object.keys(data).forEach((key) => {
      if (key[0] === '_') {
        delete data[key]
      } else {
        clearData(data[key])
      }
    })
  }

  return data
}

export function get(url): any {
  return (params: any = {}, options?: AxiosRequestConfig) => {
    return axios.get(url, {
      params: params,
      ...options,
    })
  }
}

export function post(url, options?: AxiosRequestConfig): any {
  return (data = {}) => {
    return axios.post(url, clearData(cloneDeep(data)), options)
  }
}

export function del(url): any {
  return (params: any = {}, options?: AxiosRequestConfig) => {
    return axios.delete(url, {
      params: params,
      ...options,
    })
  }
}

export const cache = (api) => {
  let res = {}

  return async (...args) => {
    const argsStr = JSON.stringify(args)
    const cache = res[argsStr]

    if (cache) {
      return Promise.resolve(cache)
    } else {
      res[argsStr] = await api(...args)
      return res[argsStr]
    }
  }
}

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // 如果有token，每次请求都加在 headers.Authorization
    if (models.user.token) {
      config.headers.Authorization = 'JWT ' + models.user.token
    }

    // 区分8bella
    if (models.orders.isInIframe) {
      config.headers.is8bella = true
    }

    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  ({ data }) => {
    const isBizApi = data && typeof data.code !== 'undefined'
    if (!isBizApi) {
      return data
    }

    const isError = isBizApi && data.code !== 0
    if (isError) {
      return Promise.reject(data)
    }

    return data.data
  },
  async (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
        case 401:
          // 授权失效，跳转到登录页面
          models.user.logout()
          models.history.push('/login', { from: models.history.location })
          break
      }
    }
    return Promise.reject(error.response)
  }
)

// http response error 拦截器
axios.interceptors.response.use(null, (error: any) => {
  if (isObject(error)) {
    if (error.desc) {
      //message.error(error.desc)
      Modal.error({ title: '报错了。。。', content: error.desc })
    } else {
      // 400, 401 不弹出错误提示
      if (![400, 401].includes(error.status)) {
        Modal.error({ title: '报错了。。。', content: `${error.status}:${error.statusText}` })
      }

      //message.error(`${error.status}:${error.statusText}`)
    }
  } else {
    message.error(`unknown error`)
    //Modal.error({ title: '报错了。。。', content: '未知错误，请联系客服'})
  }

  return Promise.reject(error)
})

export default axios
