import qs from 'qs'
import axios from 'axios'
import { notification } from 'antd'

const getQuerystring = () => {
  const search = location.search.substr(1) || ''
  return qs.parse(search)
}

const createUID = () => {
  return Math.random()
    .toString(36)
    .substr(2, 5)
}

const checkVerison = async () => {
  const DELAY = 5 * 1000 * 60

  let curVersion = null

  const check = async () => {
    const { version }: any = await axios.get(`${location.origin}/version.json`)
    curVersion = curVersion || version

    if (curVersion === version) {
      return
    }

    notification.success({
      message: '系统已更新',
      description: '您可以刷新浏览器使用最新的系统',
      duration: null
    })
    curVersion = version
  }

  const loop = async () => {
    try {
      await check()
      setTimeout(loop, DELAY)
    } catch (err) {
      throw new Error(err)
    }
  }

  loop()
}

export { getQuerystring, createUID, checkVerison }
