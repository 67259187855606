import { observable } from 'mobx'

import api from '../api'

export default class Cpm {
  root: any

  constructor(root) {
    this.root = root
  }

  @observable
  detail: any = {}

  @observable
  cpms: any = {
    data: [],
    paginator: {}
  }

  @observable
  cpmtags: any = []

  async fetchCpmList(params?: { page: number }) {
    const res = await api.fetchCpmList(params)
    this.cpms = res
    return res
  }
  
  async fetchCpmDetail(params: { number: string}) {
    const res = await api.fetchCpmDetail(params)
    this.detail = res.data
    return res.data
  }

  async fetchCpmTagList() {
    const res = await api.fetchCpmTagList()
    this.cpmtags = res
    return res
  }
}
