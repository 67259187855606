import { history } from '../routes'
import User from './User'
import Orders from './Orders'
import Good from './Good'
import Cpm from './Cpm'
import Address from './Address'
import Base from './Base'
import Billing from './Billing'
import Affiliate from './Affiliate'
import Mall from './Mall'
import Zhuanyun from './Zhuanyun'

class RootStore {
  history = history
  user = new User(this)
  orders = new Orders(this)
  good = new Good(this)
  cpm = new Cpm(this)
  address = new Address(this)
  base = new Base(this)
  billing = new Billing(this)
  affiliate = new Affiliate(this)
  mall = new Mall(this)
  zhuanyun = new Zhuanyun(this)
}

const rootStore = new RootStore()
;(window as any).rootStore = rootStore

export default rootStore
