import { observable } from 'mobx'

export default class User {
  root: any

  constructor(root) {
    this.root = root
  }

  @observable
  meta: any = {}
}
