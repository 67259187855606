module.exports = {
	"accountInformation": "账户信息",
	"orders": "订单查询",
	"lables": "面单管理",
	"accountBalance": "账户余额",
	"myLoginDetails": "用户信息",
	"bussinessAccount": "商业用户",
	"companyInformation": "公司信息",
	"downloadInvoiceFaPiao": "发票下载",
	"commercialDeclaration": "商业报关",
	"myLuggageWode": "我的寄存",
	"luggageInformation": "入库行李",
	"discount": "优惠信息",
	"myRewards": "我的返利",
	"myCoupon": "我的卡券",
	"giftMoney": "我的红包",
	"others": "常用工具",
	"declaration": "333物品申报",
	"addressBook": "地址簿",
	"siteMessage": "站内信",
	"preferenceSetting": "偏好设置",
	"internationalParcels": "国际订单",
	"ukParcels": "内陆订单",
	"balanceToPay": "补款订单",
	"packagingOrder": "包材订单",
	"collectionInHongkong": "香港自提",
	"recipient": "收件人",
	"recipientsContactNumber": "收件人电话",
	"orderNumber": "单号",
	"services": "服务",
	"time": "时间",
	"search": "搜索",
	"combinedLabels": "合并面单",
	"dataExport": "导出数据",
	"trackingStatus": "物流状态",
	"all": "全部",
	"waitingForCollection": "等待揽收",
	"collected": "已揽收",
	"processed": "已处理",
	"deliveryInChina": "国内派送",
	"cancelled": "已取消",
	"ordersWithLabelCreationFailed": "查看出单失败的订单",
	"ordersAwaitingForRecipientIdUpload": "查看待上传身份证的订单",
	"serviceFWLX": "订单服务路线",
	"serviceLuxian": "服务路线",
	"sender": "寄件人",
	"destination": "目的地",
	"weightDeclared": "申报重量",
	"parcelDeclared": "包裹申报",
	"shippingFee": "运费总金额",
	"status": "状态",
	"action": "操作",
	"parcelNumber": "包裹单号",
	"declaredStatus": "申报状态",
	"certificate": "报关单",
	"orderNumber/trackingNumber": "订单号/国际追踪号",
	"ukTrackingNumber": "内陆追踪号",
	"labelToDownload": "查看面单",
	"orderDetails": "查看订单",
	"duplicateOrder": "复制订单",
	"orderNumberDing": "订单号",
	"trackingNumber": "追踪号",
	"serviceName": "服务名称",
	"collectionTime": "取件时间",
	"quantity": "数量",
	"name": "姓名",
	"telephone": "电话",
	"orderStatus": "包裹状态",
	"orderDate": "下单时间",
	"payDate": "支付时间",
	"orderNumberIntl": "国际服务单号",
	"feeCategory": "类型",
	"amount": "金额",
	"note": "备注",
	"packagingProducts": "包材类型",
	"postCode": "邮编",
	"orderInformation": "订单信息",
	"orderValue": "订单金额",
	"courierService": "派送公司",
	"trackingNumberPaiSong": "派送单号",
	"applyForSelfCollction": "申请自提",
	"parcelArrived": "到达仓库",
	"waitingForCollectionTiHuo": "等待提货",
	"collectionCompleted": "已完成提货",
	"parcelWeight": "包裹重量",
	"inboundDate": "入库时间",
	"storagePeriod": "到达天数",
	"7DaysFreeStorage,5CnyPerDayAfter,SettleAtCollection": "7天免仓租，超出后按5元/天计费， 提货时结算",
	"unprinted": "尚未打印",
	"printed": "已打印",
	"markAsPrinted": "标记为已打印",
	"topupInformation": "充值信息",
	"transactionRecord": "余额明细",
	"accountBalanceMuQian": "您目前的账户余额",
	"rewardsEarned": "您目前的返佣余额",
	"topupAmount": "充值金额",
	"otherAmount": "自定义",
	"topUp": "充值",
	"today": "本日",
	"thisWeek": "本周",
	"thisMonth": "本月",
	"thisYear": "本年",
	"date": "日期",
	"amountYuE": "余额",
	"paymentMethod": "支付方式",
	"transactionType": "消费类型",
	"noteXiaoFei": "消费备注",
	"country": "国家",
	"userName": "用户名",
	"emailAddress": "电子邮件",
	"cityChengShi": "城市",
	"qqNumber": "QQ",
	"wechatId": "微信",
	"password": "密码",
	"confirmPassword": "重复密码",
	"emailSubscription": "订阅邮件",
	"subscribed": "已订阅",
	"save": "保存",
	"accountType": "发票类型",
	"companyName": "公司名称",
	"vat/EoriNumber": "VAT / EORI 税号",
	"contactName": "联系人名称",
	"contactNumber": "联系人电话",
	"address1": "地址",
	"address2": "地址2",
	"address3": "地址3",
	"business": "企业",
	"individual": "个人",
	"invoiceNumber": "发票号",
	"dateCreated": "创建时间",
	"downloadInvoice": "下载发票",
	"downloadExportCertificate": "下载离境证明",
	"combineInvoices": "合并发票",
	"combineExportCertificate": "合并报关单",
	"downloadClick": "点击下载",
	"returnLuggage": "寄还行李",
	"myLuggage": "我的行李",
	"orderNumberJiCun": "寄存订单号",
	"collectionDate": "取件日期",
	"outboundDate": "出库时间",
	"storageFee": "寄存费用",
	"submit": "提 交",
	"couponsAvailable": "可领取卡券",
	"couponsCanUse": "可用的优惠券",
	"priceList": "专属报价",
	"couponsToBeRedeemed": "可用卡券",
	"availableLuckyMoney": "可用红包",
	"expiredLuckyMoney": "过期红包",
	"declarationForm": "新增申报",
	"myDeclaration": "我的申报",
	"serviceSelected": "申报服务",
	"countryOfOrigin": "原产地",
	"brandEn": "英文品牌",
	"itemDescription": "英文物品名称",
	"brandInChinese": "中文品牌",
	"putN/aIfNoChineseBrandName": "例如 爱他美，若次品牌没有中文名称，请直接填写 无中文",
	"itemDescriptionInChinese": "中文物品名称",
	"productCategory": "物品分类",
	"itemPrice": "物品价格",
	"specification": "规格",
	"unit": "计量单位",
	"forExample:Bottle,Box": "例如 瓶，支，盒",
	"netWeight(g)": "物品单位净重",
	"itemLink": "网上购买链接",
	"receipt": "小票图片",
	"uploadPic": "选择图片",
	"reset": "重置",
	"itemDescriptionInEnglish/Chinese": "英文/中文名称",
	"brand": "品牌",
	"price": "价格",
	"net/GrossWeight": "净重/毛重",
	"recipient'sAddress": "收件人地址",
	"addRecipient'sAddress": "添加收件人地址",
	"tel": "手机号码",
	"province": "省",
	"city": "市",
	"district": "区",
	"recipientIdNumber": "身份证号码",
	"default": "是否默认",
	"sender'sAddress": "寄件人地址",
	"address": "具体地址",
	"addSender'sAddress": "添加寄件人地址",
	"title": "标题",
	"messageType": "通知类型",
	"read": "是否已读",
	"yes": "需要",
	"noNeed": "不需要",
	"oneOrderOneEmailNotifiation": "每个单号单独发一封邮件",
	"oneEmailNotificationForOnePayment": "将购物车一起付款的所有订单合并为一封邮件",
	"welcome": "欢迎您",
	"sameParcel": "相同包裹",
	"orderTrackAlert": "您可以在此页面内下载包裹快递面单、查看包裹订单信息、追踪包裹",
	"orderTrackDescription": "下单成功后的包裹需要点击\"查看面单\"打印包裹快递面单，确保面单条码足够清晰并贴在箱子面积最大的一面，然后可以根据你选择的投递服务送去相应的投递点或等待司机上门取件",
	"planDetails": "申请理由",
	"setDefaultPaymentMethod": "设置默认支付方式",
	"emailNotification": "设置是否接受面单邮件",
	"newsCenter": "新闻中心",
	"internationalPostalService": "万国邮政",
	"laPoste": "法国邮政",
	"royalMailSmallParcel": "Royal Mail皇家小包",
	"bpost": "Bpost比利时邮政",
	"pf": "ParcelForce小包",
	"PFGlobalDirect": "ParcelForce经济包",
	"PFGlobalPriority": "ParcelForce优先包",
	"333DDP": "333杂货包税",
	"333plus": "333Plus杂货",
	"4DDP": "四罐奶粉",
	"6DDP": "六罐奶粉",
	"groceriesSmallParcelDDP": "杂货小包",
	"skincareSmallParcelDDP": "护肤品小包",
	"giftsetSmallParcelDDP": "套装专线",
	"etk": "E特快专线",
	"luggagePostalService": "空运行李",
	"upsExpress": "UPS文件特快",
	"milkDDP": "奶粉包税",
	"miscDDP": "杂货包税",
	"noData": "暂无",
	"collection": "上门取件",
	"dropOff": "自送网点",
	"selfDropOff": "自行安排",
	"ordersGaiKuang": "包裹概况",
	"seeAllOrders": "查看所有订单",
	"faq": "常见问题",
	"consultAndFaq": "咨询与常见问题",
	"myOrders": "我的订单",
	"shopOrders": "代发订单"
};