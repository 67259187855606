module.exports = {
	"site": "站点",
	"shippingQuote": "运费报价",
	"myAccount": "我的账户",
	"cart": "购物车",
	"cpm": "问题件",
	"logout": "退出",
	"home": "首页",
	"servicesAndProducts": "服务与产品",
	"findAService": "快递直邮",
	"freightForwarding": "大宗出口",
	"haitaoTransshipment": "海淘转运",
	"fulfillmentService": "代发商城",
	"promotions": "优惠活动",
	"mall": "商城",
	"packingMaterials": "包装材料",
	"8bella": "百宝来商城",
	"about": "关于我们",
	"news": "新闻中心",
	"enterPackageNumber": "输入SD追踪号",
	"tracking": "包裹追踪",
	"welcome": "欢迎您",
	"newNotify": "您有新的消息",
	"systemNotice": "系统公告",
	"csNotice": "客服通知",
	"switchCountry": "切换国家",
	"deStation": "德国站",
	"ukStation": "英国站",
	"gbp": "英镑",
	"eur": "欧元",
	"name": "姓名",
	"phone": "电话",
	"city": "城市",
	"street1": "地址1",
	"street2": "地址2",
	"street3": "地址3",
	"state": "state",
	"postcode": "邮编",
	"house": "门牌号",
	"street": "地址",
	"idnumber": "身份证",
	"noData": "暂无数据",
	"addressbook": "地址簿",
	"address": "地址",
	"chooseAddress": "请选择地址",
	"order:z4pdxw": "单个物品重量"
};