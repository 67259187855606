import * as React from 'react'
import { hot } from 'react-hot-loader/root'
import { Switch, Route, Redirect } from 'react-router-dom'
import createBrowserHistory from 'history/createBrowserHistory'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import loadable from 'loadable-components'

import models from '../models'
import mainConfigs from './main'

const browserHistory = createBrowserHistory()
const routerStore: any = new RouterStore()
const history = syncHistoryWithStore(browserHistory, routerStore)

export const renderRoutes = (routes, moreProps = {}) => (
  <Switch>
    {routes.map(({ component: Component, meta = {}, routes, ...rest }: any) => (
      <Route
        key={rest.path}
        {...rest}
        render={(props: any) => {
          const routeProps = {
            ...props,
            ...moreProps,
            meta,
            routes,
            renderSubRoutes: otherProps => renderRoutes(routes, otherProps)
          }
          models.base.meta = meta

          if (process.env.NODE_ENV === 'production' && ['/', '/react'].includes(rest.path)) {
            window.location.replace('/')
            return null
          }

          if (!meta.isPrivate) {
            return <Component {...routeProps} />
          }

          const hasToken = localStorage.getItem('jwttoken')
          return hasToken ? (
            <Component {...routeProps} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }}
      />
    ))}
  </Switch>
)

const Routes = () => {
  const App = loadable(() => import('../views/App'))
  return <App>{renderRoutes(mainConfigs)}</App>
}

export { history }
export default hot(Routes)
