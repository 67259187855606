import i18n from './locales'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { Router } from 'react-router'
import { I18nextProvider } from 'react-i18next'

import models from './models'
import Routes, { history } from './routes'
import './global.scss'
import { HOSTS } from './constants'
import SentryReport from './components/Sentry'
import { checkVerison } from './utils'

i18n.on('languageChanged', function (lng) {
  models.user.curLng = lng.split('-')[0]
})
models.user.curLng = i18n.language

if (models.user.token) {
  models.user.fetchDetail()
  models.user.fetchSettings()
} 

window.recaptchaOptions = {
  useRecaptchaNet: true,
};

document.documentElement.classList.add(`site-${HOSTS.current}`)
checkVerison()

ReactDOM.render(
  <SentryReport>
    <I18nextProvider i18n={i18n}>
      <Provider {...models}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    </I18nextProvider>
  </SentryReport>,
  document.getElementById('app')
)
