import countryData from '../components/CountrySelect/country.data.json'

let uk = 'send2china.co.uk'
let de = 'send2china.de'

// 本地开发环境
if (process.env.DEV_ENV === 'local') {
  uk = 'www.s2clocal.co.uk'
  de = 'www.s2clocal.de'
}

export const HOSTS = {
  uk,
  de,
  get current(): string {
    const host = location.hostname
    let result =
      (host.indexOf(this.uk) !== -1 && 'uk') || (host.indexOf(this.de) !== -1 && 'de') || 'unknown'

    if (host == 'ukuk.send2china.com') {
      result = 'uk'
    } else if (host === 'de.send2china.com' || host === 'dede.send2china.com') {
      result = 'de'
    }

    if (result === 'unknown') {
      console.warn('匹配不到站点，页面可能会出错')
    }

    return result
  },
}

export const I18N = {
  de: 'German',
  en: 'English',
  zh: '中文',
}

export const addressMap = countryData.reduce((acc, cur) => {
  acc[cur[0]] = cur[2]
  return acc
}, {})

export enum AddressType {
  sender = 'sender',
  receiver = 'receiver',
}

export const OrderStatus = {
  '-20': 'Draft',
  0: 'Deleted',
  10: '订单未支付',
  15: '订单已取消',
  20: '订单已支付',
  30: '订单已处理',
  40: 'Handled',
  50: 'Departed',
  60: 'Landed',
  70: 'Customs',
  80: 'InTransit',
  90: 'Delivered',
  100: 'AttemptFail',
  110: 'Modified',
}

export const ZTOrderStatus = {
  0: '--',
  5: '到达仓库',
  10: '等待提货',
  15: '已完成提货',
}

export const HandlingOrderDisplay = {
  1: 'Handling fee: overweight',
  2: 'Handling fee: repack',
  3: 'Handling fee: return',
  4: 'Handling fee: other',
  5: 'Handling fee: ETK duty',
  10: 'Handling fee: violation',
  20: 'Handling fee: cancel fee',
  30: 'Handling fee: cancel replenishment',
}

export const RouteStatus = {
  50: '注册生成',
  110: '上门取件成功',
  111: '上门取件失败',
  115: '网点揽收',
  120: '分拨中心揽收',
  130: '集运中心揽收不处理',
  135: '集运中心揽收并处理',
  136: '包裹超重',
  140: '超限信息',
  150: '集运中心揽收并处理',
  200: '发往机场途中',
  250: '离境发往中国',
  300: '到达国际机场',
  320: '派送单号信息',
  350: '到达海关处理中心',
  370: '清关完成',
  500: '运输中',
  600: '派送中',
  700: '包裹异常',
  800: '拒签',
  900: '待提货',
  1000: '已签收',
}
export const LuggageStatus = {
  0: '已删除',
  5: '已过期',
  10: '未付款',
  15: '已取消',
  20: '已支付',
  30: '已入库',
  40: '出库中',
  50: '已出库',
}
export const LuggageExpress = {
  0: 'Parcel Force',
  1: 'DPD',
  2: '速递中国',
}
export const PayMethod = {
  0: {
    id: 0,
    name: 'Bank Transfer',
    cnName: '银行转账',
  },
  2: {
    id: 2,
    name: 'Sofort',
    cnName: 'Sofort',
  },
  3: {
    id: 3,
    name: 'Alipay',
    cnName: '支付宝',
  },
  4: {
    id: 4,
    name: 'Wechat Pay',
    cnName: '微信支付',
  },
  5: {
    id: 5,
    name: 'Account Balance',
    cnName: '账户余额',
  },
  6: {
    id: 6,
    name: 'Point',
    cnName: 'Point',
  },
  7: {
    id: 7,
    name: 'Manual Charge by Admin',
    cnName: 'Manual Charge by Admin',
  },
  8: {
    id: 8,
    name: 'Return to Bank',
    cnName: 'Return to Bank',
  },
  9: {
    id: 9,
    name: 'Red Packet',
    cnName: '红包',
  },
  10: {
    id: 10,
    name: 'Alipay',
    cnName: '支付宝',
  },
  11: {
    id: 11,
    name: 'Wechat Pay',
    cnName: '微信支付',
  },
  12: {
    id: 12,
    name: 'Convert from affiliate',
    cnName: 'Convert from affiliate',
  },
  13: {
    id: 13,
    name: 'Claim',
    cnName: 'Claim',
  },
  14: {
    id: 14,
    name: 'Store Profit Sharing',
    cnName: 'Store Profit Sharing',
  },
  15: {
    id: 15,
    name: 'Barclay',
    cnName: 'Barclay',
  },
}
