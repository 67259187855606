import { observable, autorun, computed } from 'mobx'
import api from '../api'
import { createUID } from '../utils'
import get from 'lodash/get'
import { HOSTS } from '../constants'

export interface IContent {
  _id?: number
  goods_name_cn?: string
  goods_name_en?: string
  goods_currency: string
  unit_price: number
  net_weight?: number
  tax?: number
  addition_fee?: number
  quantity: number
  brand?: string
  goodsunit?: string
}

interface ISize {
  claimed_length?: number
  claimed_width?: number
  claimed_height?: number
  claimed_gross_weight?: number
  country?: string
}

export interface IParcel {
  _id: number
  _addressInfo: any
  receiver_id?: number
  packages: IPackage[]
  consignment_group: string
  same_order_count: number
}
export interface IPackage extends ISize {
  _id: number
  id?: number
  name?: number
  _insurance?: any
  _additional?: any
  _intlService?: any
  _consignmentService?: any
  intlservice_id?: number
  returnservice_id?: number
  insurance_id?: number | ''
  additional_id?: number | ''
  notes?: string
  contents: IContent[]
  consignment_group: string
  _goodsCheck?: any
  same_order_count: number
}
export interface IOrder {
  orderId?: number
  sender_id?: number
  _sendAddress?: any
  return_order?: {
    store?: any
    _store?: any
    _returnService?: any
    return_service_id?: number
    quantity?: number
    notes?: string
    receiver_id?: number
    store_id?: number
    dropoffpoint_id?: number
    pickup_time?: string
  }
  _ishopperInfo: any
  parcels: IParcel[]
}

export default class Orders {
  root: any
  constructor(root) {
    this.root = root

    try {
      // 从 ls 取出之前存储的 orderItems
      const localOrder = sessionStorage.getItem('localOrder')
      const localIntlService = sessionStorage.getItem('localIntlService')
      if (localOrder) {
        this.order = JSON.parse(localOrder)
      }
      if (localIntlService) {
        this.intlService = JSON.parse(localIntlService)
      }
    } catch (err) {
      console.error(err)
    }

    autorun(() => {
      // 自动同步到本地
      sessionStorage.setItem('localOrder', JSON.stringify(this.order))
      sessionStorage.setItem('localIntlService', JSON.stringify(this.intlService))
    })
  }

  @observable
  order: IOrder = {
    return_order: {},
    parcels: [],
    _ishopperInfo: {},
  }

  @observable
  isInIframe = false

  @observable
  intlService = null

  @observable
  intlServices = []

  @observable
  intlServicesExtra = {}

  @observable
  returnServices = []

  @observable
  insurances = []

  @observable
  additional = []

  @observable
  shoporder = []

  @observable
  zhuanyunorder = []

  @observable
  sadOrders = []

  // 声明 cart 的存储字段
  @observable
  cart: any = []

  @observable
  parcelorder: any = []

  @observable
  parcelorderList: any = {
    data: [],
    paginator: {},
  }

  @observable
  unprint_parcelorderList: any = {
    data: [],
    paginator: {},
  }

  @observable
  print_parcelorderList: any = {
    data: [],
    paginator: {},
  }
  @observable
  handlingorder: any = []

  @observable
  handlingorderList: any = {
    data: [],
    paginator: {},
  }
  @observable
  returnorder: any = []

  @observable
  returnorderList: any = {
    data: [],
    paginator: {},
  }

  @observable
  ztorderList: any = {
    data: [],
    paginator: {},
  }

  @observable
  mallorder: any = []

  @observable
  mallorderList: any = {
    data: [],
    paginator: {},
  }

  @observable
  shoporderList: any = {
    data: [],
    paginator: {},
  }

  @observable
  zhuanyunorderList: any = {
    data: [],
    paginator: {},
  }

  @observable
  storeList: any = []

  @observable
  quickParcels: IPackage[] = []

  @observable
  luggageorder: any = []

  @observable
  luggageOrderList: any = {
    data: [],
    paginator: {},
  }

  @computed
  get storeListCascader() {
    return this.storeList
  }

  @computed
  get mainParcel(): IParcel | any {
    return !!this.order.parcels.length && this.order.parcels[0]
  }

  @computed
  get packages(): IPackage[] {
    const { parcels } = this.order
    return parcels.reduce((acc, cur) => {
      cur.packages.forEach((packageItem) => {
        acc.push(packageItem)
      })
      return acc
    }, [])
  }

  @computed
  get intlServiceCates(): string[] {
    let cates = []

    if (this.intlServices.length) {
      cates = this.intlServices.reduce((acc, cur) => {
        acc = [...acc, ...cur.categories]
        return acc
      }, [])
    }

    return [...new Set(cates)]
  }

  @computed
  get intlServiceByCates(): any[] {
    const results: { cate: string; items: any }[] = []

    this.intlServices.forEach((serviceItem) => {
      serviceItem.categories.forEach((cate) => {
        let curCate = results.find((resultItem) => resultItem.cate === cate)

        if (!curCate) {
          curCate = { cate, items: [] }
          results.push(curCate)
        }

        curCate.items.push(serviceItem)
      })
    })

    return results
  }

  @computed
  get returnServicesByType(): any[] {
    const currentSite = HOSTS.current
    let t = i18n.t('account:dropOff')
    if (currentSite === 'de') {
      t = '自送网点(UPS回邮单购买)'
    }

    const result = [
      { title: t, type: ['drop_off', 'store'], data: [] },
      { title: i18n.t('account:collection'), type: ['collection'], data: [] },
      { title: i18n.t('account:selfDropOff'), type: ['self'], data: [] },
    ]

    this.returnServices.forEach((serviceItem) => {
      result.forEach((typeItem) => {
        if (typeItem.type.includes(serviceItem.service_type)) {
          typeItem.data.push(serviceItem)
        }
      })
    })

    return result
  }

  get parcelsSum() {
    return this.packages
      .reduce((acc, cur) => {
        acc += Number(this.countFeeByPackage(cur).total)
        return acc
      }, 0)
      .toFixed(2)
  }

  getPriceSumByPackage(packageItem: IPackage) {
    return packageItem.contents.reduce((acc, cur) => {
      acc += Number(cur.unit_price) * Number(cur.quantity)
      return acc
    }, 0)
  }

  async consignmentValidator(packages: IPackage[]) {
    const consignment = packages.reduce((acc, cur) => {
      acc.push({
        claimed_gross_weight: cur.claimed_gross_weight,
        claimed_height: cur.claimed_height,
        claimed_length: cur.claimed_length,
        claimed_width: cur.claimed_width,
      })
      return acc
    }, [])
    const params = { ...packages[0], consignment }

    const [res] = await this.fetchServices(params)

    packages.forEach((item) => {
      item._consignmentService = res
    })
  }

  countFeeByPackage(packageItem: IPackage) {
    const { order } = this

    const fees: any = {
      intlFee: Number(get(packageItem, '_intlService.price', 0)),
      returnFee: Number(get(order.return_order, '_returnService.price', 0)),
      addition: packageItem._goodsCheck.addition_fee || 0,
      serviceAdditional: Number(get(packageItem, '_additional.fee', 0)),
      tax: packageItem._goodsCheck.tax || 0,
      insurance: Number(get(packageItem, '_insurance.fee', 0)),
      fuelFee: Number(get(packageItem, '_intlService.ful_fee', 0)),
      get total() {
        return Object.keys(fees)
          .filter((key) => key !== 'total')
          .reduce((acc, cur) => ((acc += fees[cur]), acc), 0)
      },
    }

    return fees
  }

  resetOrder() {
    this.order = {
      return_order: {},
      parcels: [],
      _ishopperInfo: {},
    }
    this.intlServices = []
    this.returnServices = []
    this.insurances = []
    this.additional = []
    this.addParcel()
  }

  async initOrderById(number: string) {
    this.resetOrder()

    const res = (await this.fetchParcelOrder({ number })).data[0]

    this.intlService = res.service
  }

  addParcel(packageItemTemplate?: IPackage) {
    const { parcels } = this.order
    const parcelItem: any = {
      _id: createUID(),
      receiver_id: null,
      packages: [],
      same_order_count: 1,
    }

    parcelItem.packages.push(this.getPackageByIntlService(packageItemTemplate))
    parcels.push(parcelItem)

    return parcelItem
  }

  getPackageByIntlService(packageItemTemplate?: IPackage) {
    const intlService = this.intlService
    const returnOrder = this.order.return_order
    const insurances = this.insurances
    const additional = this.additional
    const mainPackage = get(this, 'mainParcel.packages[0]', {})
    const data: any = {
      _id: createUID(),
      intlservice_id: null,
      country: mainPackage.country,
      claimed_gross_weight: undefined,
      claimed_length: undefined,
      claimed_width: undefined,
      claimed_height: undefined,
      insurance_id: '',
      additional_id: '',
      notes: '',
      contents: [],
      _goodsCheck: {},
    }

    if (insurances && insurances.length === 1) {
      data.insurance_id = insurances[0].id
      data._insurance = insurances[0]
    }

    if (additional && additional.length === 1) {
      data.additional_id = additional[0].id
      data._additional = additional[0]
    }
    if (returnOrder.return_service_id) {
      data.return_service_id = returnOrder.return_service_id
      data.returnservice_id = returnOrder.return_service_id
    }

    if (intlService) {
      data.intlservice_id = intlService.id
      data.contents.push(this.getParcelContentByIntlService())
    }

    if (packageItemTemplate) {
      data.claimed_gross_weight = packageItemTemplate.claimed_gross_weight
      data.claimed_length = packageItemTemplate.claimed_length
      data.claimed_width = packageItemTemplate.claimed_width
      data.claimed_height = packageItemTemplate.claimed_height

      if (packageItemTemplate.insurance_id) {
        data.insurance_id = packageItemTemplate.insurance_id
        data._insurance = packageItemTemplate._insurance
      }

      if (packageItemTemplate.additional_id) {
        data.additional_id = packageItemTemplate.additional_id
        data._additional = packageItemTemplate._additional
      }
    }

    return data
  }

  getParcelContentByIntlService() {
    const intlService = this.intlService
    const data: any = {
      _id: createUID(),
      goods_currency: intlService.currency,
      goods_id: '',
      unit_price: undefined,
      net_weight: 0,
      quantity: 1,
      goods_name_en: undefined,
      goods_name_cn: undefined,
      brand: undefined,
      goodsunit: undefined,
      category: undefined,
    }

    return data
  }

  async fetchReturnServices(params?) {
    let return_services = await api.fetchOrderReturnServices(params)
    this.returnServices = return_services

    return return_services
  }

  async fetchServices(params: ISize & { returnservice_id?: number; intlservice_id?: number }) {
    let res = await api.fetchOrderServices({
      ...params,
      sender_postal: this.order?._sendAddress?.postcode,
    })

    res = res.map(({ price, chargable_weight, service, msg, lowest_price, ful_fee }) => {
      return { ...service, chargable_weight, price, msg, lowest_price, ful_fee }
    })
    this.intlServices = res
    return res
  }

  async fetchInsurances(serviceId: number) {
    let res = await api.fetchInsurances({ intlservice_id: serviceId })
    this.insurances = res.data
  }

  async fetchAdditional(serviceId: number) {
    let res = await api.fetchAdditional({ intlservice_id: serviceId })
    this.additional = res.data
  }

  async fetchZTOrder(params) {
    let res = await api.parcelorder({
      ...params,
      mark: 'ZT',
      status_min: 20,
    })
    this.ztorderList = res
    return res
  }

  async fetchParcelOrder(params) {
    let res = await api.parcelorder({ ...params })
    this.parcelorderList = res
    return res
  }
  async fetchPrintedParcelOrder(params) {
    let res = await api.parcelorder({ ...params, is_printed: true })
    this.print_parcelorderList = res
    return res
  }
  async fetchunPrintedParcelOrder(params) {
    let res = await api.parcelorder({ ...params, is_printed: false })
    this.unprint_parcelorderList = res
    return res
  }
  async fetchMallOrder(params) {
    let res = await api.fetchMallOrders({ ...params })
    this.mallorderList = res
    return res
  }
  async fetchShopOrder(params) {
    let res = await api.fetchShopOrders({ ...params })
    this.shoporderList = res
    return res
  }
  async fetchZhuanyunOrder(params) {
    let res = await api.fetchZhuanyunOrders({ ...params })
    this.zhuanyunorderList = res
    return res
  }

  // 从后端获取 cart 的信息，并存储
  async fetchCart() {
    let [
      parcelorder,
      handlingorder,
      returnorder,
      mallorder,
      luggageorder,
      shoporder,
      zhuanyunorder,
      sadOrders,
    ] = await Promise.all([
      this.fetchParcelOrder({ status: 10, perpage: 999, source: 'cart' }),
      api.handlingorder({ status: 10, perpage: 999, source: 'cart' }),
      api.returnorder({ status: 10, perpage: 999, source: 'cart' }),
      api.fetchMallOrders({ status: 10, perpage: 999, source: 'cart' }),
      api.getLuggageOrders({ status: 10, perpage: 999, source: 'cart' }),
      api.fetchShopOrders({ status: 10, perpage: 999, source: 'cart' }),
      api.fetchZhuanyunOrders({ status: 10, perpage: 999, source: 'cart' }),
      api.fetchSadOrders({ status: 10, perpage: 999, source: 'cart' }),
    ])

    this.parcelorder = parcelorder.data
    this.handlingorder = handlingorder.data
    this.returnorder = returnorder.data
    this.mallorder = mallorder.data
    this.luggageorder = luggageorder.data
    this.shoporder = shoporder.data
    this.zhuanyunorder = zhuanyunorder.data
    this.sadOrders = sadOrders.data
  }

  //重置购物车中parcelorder未付款订单的优惠
  async resetDiscount(params) {
    return await api.reset_discount({ ...params })
  }

  async fetchStoreList() {
    const { data } = await api.fetchStoreList()
    this.storeList = data
  }

  async fetchReturnOrder(params) {
    let res = await api.returnorder({ ...params })
    this.returnorderList = res
    return res
  }

  async fetchHandlingOrder(params) {
    let res = await api.handlingorder({ ...params })
    this.handlingorderList = res
    return res
  }

  async updatePackageIntlService() {
    const isConsignment = typeof this.intlService.consignment_size === 'number'

    if (isConsignment) {
      this.order.parcels.forEach((item) => {
        this.consignmentValidator(item.packages)
      })
      return
    }

    Promise.all(
      this.packages.map(async (packageItem) => {
        const res = await this.fetchServices(packageItem)
        packageItem._intlService = res[0]
      })
    )
  }
  async CancelOrder(params) {
    return await api.cancelorder({ ...params })
  }

  updateParcelorder(params: { number: string; [key: string]: any }) {
    return api.updateParcelorder(params)
  }

  async goodsCheck(params) {
    const res = await api.validParcelContent(params)
    return res
  }
  async MergeParcelPdf(params) {
    return await api.mergeParcelPdf(params)
  }
  async pickupHK(params) {
    return await api.pickupHK(params)
  }
  async pickupHKcancel(params) {
    return await api.pickupHKcancel(params)
  }
  async qrcodeHK(params) {
    return await api.qrcodeHK(params)
  }
  async ExportParcelExcel(params) {
    return await api.exporteParcelExcel(params)
  }
  async UpdateParcelPrinted(params) {
    return await api.updateParcelPrinted(params)
  }

  async fetchQuickParcels() {
    const res = await api.fetchQuickparcel()

    this.quickParcels = res
    return res
  }
  async fetchLuggageOrders(params) {
    const res = await api.getLuggageOrders(params)
    this.luggageOrderList = res
    return res
  }
  async fetchIntlServiceExtra(params) {
    const res = await api.fetchOrderServicesExtra(params)
    this.intlServicesExtra = res
    return res
  }
}
