module.exports = {
	"email": "email",
	"password": "password",
	"login": "Log in",
	"pleaseInputYourPhone": "Please input your phone",
	"pleaseInputYourUsername": "Please input your username",
	"pleaseInputYourPassword": "Please input your password",
	"loginByAccount": "Login by account",
	"loginByPhone": "Login by phone",
	"remember": "Remember",
	"notRegistered": "Not a member",
	"registerNow": "Register Now",
	"forgetPassword": "Forget password",
	"changePassword": "Change Password",
	"confirm": "Confirm",
	"fillInYourInfo": "Register",
	"completeInYourInfo": "Complete",
	"agreeTerm": "I have read and understood the terms and conditions",
	"nextStep": "Next Step",
	"previousStep": "Previous Step",
	"haveAccount": "Already have a account",
	"enterVerificationCode": "Enter verification code",
	"countdown": "Coundown",
	"getVerificationCode": "Get verification code",
	"username": "Username",
	"usernameOptional": "Username(Optional)",
	"enterCorrectUsername": "only allow letter of the alphabet,digital and underline",
	"confirmPassword": "Confirm your password",
	"usernameOrEmail": "Username or email",
	"enterCorrectPhone": "Please enter phone number not start with 0",
	"germanPostcode": "German postcode",
	"enterPostcode": "Plase enter postcode",
	"enterState": "please enter state",
	"onlyDigital": "Only digital allowed",
	"ukPostcode": "UK postcode",
	"pleaseenterukPostcode": "Please enter UK postcode",
	"onlyazand09": "only allow letter of the alphabet and digital",
	"city": "City",
	"enterCity": "Please enter city",
	"codeSent": "verification code has sent",
	"noZeroforPhone": "Don't start with 0 in your phone number",
	"passwordNotSame": "Passwords are not same"
};