module.exports = {
	"weight": "Weight",
	"width": "Width",
	"length": "Length",
	"height": "Height",
	"size": "Dimensions",
	"destination": "Destination",
	"correctNumber": "Please input correct numbers",
	"freightEstimate": "Subtotal",
	"reevaluate": "Reevaluate",
	"selectParcelType": "Please select your parcel type",
	"fee": "Subtotal",
	"timeliness": "Estimated Transit Time",
	"serviceDetail": "Detail",
	"serviceNotAvailable": "Not Available",
	"selectService": "Select",
	"correctSizeWeight": "Please enter correct size and weight",
	"chooseService": "Choose a service",
	"chooseReturnService": "Collection or drop-off",
	"fillParcelInfo": "Address & consignment summary",
	"confirmOrder": "Order confirmation",
	"returnPrevious": "Return",
	"attention": "Attention",
	"returnServiceDestIsSend2China": "The Destination of Return Service is the warehouses of Send2China",
	"minimumQty": "minimum quantity",
	"addressNotAvailableForPickup": "Sorry,your address is not available",
	"noData": "No data",
	"queryingAddressData": "Processing",
	"name": "Name",
	"enterName": "Please enter your name",
	"englishOnly": "English Only",
	"phone": "Phone",
	"enterPhone": "Please enter your phone number",
	"enterCorrectPhone": "Please enter correct phone number",
	"postcode": "Postcode",
	"enterPostcode": "Please enter postcode",
	"enterState": "please enter state",
	"enter5digital": "Please enter 5-digital",
	"address": "Address",
	"enterAddress": "Please enter address",
	"houseNumber": "House Number",
	"enterHouseNumber": "Please enter house number",
	"chineseOnly": "Chinese Only",
	"chooseAddress": "Please select an address",
	"concreteStreet": "Concrete street",
	"chineseStreet": "Chinese Street",
	"enterchineseStreet": "Please enter Chinese street",
	"idNumber": "ID number",
	"enterIdNumber": "Please enter ID Number",
	"enterCorrectIdNumber": "Please enter correct ID Number",
	"saveAddressDefault": "Make this address the default for future orders",
	"saveAddressNew": "Save this address for use with future orders",
	"pickService": "Pickup service",
	"pickTime": "Pickup time",
	"pickDate": "Pickup date",
	"selectpickDate": "Please select pickup date",
	"note": "Note",
	"changeStore": "Change to a different drop-off store",
	"choosenStore": "Chosen store",
	"continueFillReceiver": "Continue",
	"addedCart": "Added to cart",
	"serviceTerm": "Service term",
	"termConfirmed": "I confirm that the package details are correct",
	"cancel": "Cancel",
	"alreadySetDefaultReturnService": "You have selected the default return service",
	"clickToModifyReturnService": "Click to modify return service method",
	"ifNeedModifyReturnServiceGoBack": "If you need to modify return method - please go back",
	"sender": "Sender",
	"senderAndCollect": "Sender and receiver details",
	"submit": "Submit",
	"loadFromAddressBook": "Use a saved address",
	"edit": "Edit",
	"receiver": "Recipient details",
	"addReceiver": "Add to receiver",
	"selectPromoOrder": "Select the order you wish to apply your promotion code to",
	"promoCodeApplyed": "Promotion code applied",
	"couponApplyed": "Coupon applied",
	"deleteing": "Deleting",
	"doneDeleteing": "Deleted",
	"orderDeleted": "Order deleted",
	"promoCode": "Promotion code",
	"enterPromoCode": "Please enter your promotion code",
	"use": "Apply",
	"redpacket": "Red packet",
	"select": "Select",
	"noPacket": "No packet",
	"coupon": "Coupon",
	"noCoupon": "No coupon",
	"useYouhui": "Apply promotion code/coupon/red packet",
	"handlingOrder": "Pay outstanding amount",
	"orderNumber": "Order number",
	"service": "Service routes",
	"orderType": "Order types",
	"claimWeight": "State shipment weight",
	"realWeight": "Actual weight (KG)",
	"shuoming": "Declaration",
	"handlingFee": "Handling fee",
	"parcelorder": "Parcel order",
	"expiredAndClick": "Your order has timed out. Please click on the right side to edit your order.",
	"weight_kg": "Weight(KG)",
	"jine": "Amount",
	"intlfee": "International freight fee",
	"returnfee": "Domestic freight fee",
	"prepaytax": "Prepay tax",
	"additionfee": "Additional fee",
	"insurancefee": "Insurance fee",
	"discount": "Discount",
	"totalfee": "Total fee",
	"action": "Confirm",
	"editOrderCart": "Edit",
	"confirmDeleteOrder": "Confirm to delete your order",
	"confirmDelete": "Confirm to delete",
	"ok": "Ok",
	"deleteOrder": "Delete",
	"returnOrder": "Domestic order",
	"expireAndReorder": "Your order has timed out. Please delete it and redownload",
	"returnService": "Domestic service",
	"quantity": "QTY",
	"contact": "Contact",
	"pickAddrAndStore": "Pick-up address/store details",
	"mallOrder": "Mall order",
	"productList": "Product details",
	"recipient": "Receiver details",
	"productfee": "Product fee",
	"expressfee": "Freight fee",
	"amount": "Total amount",
	"loading": "Loading",
	"subtotal": "Subtotal",
	"selectall": "Select all",
	"returnAndContinueOrder": "Return and continue to order",
	"needpay": "Amount outstanding",
	"alreadydiscount": "Discount applied",
	"checkout": "Check out",
	"deleteSelected": "Delete the selected items",
	"items": "Items",
	"intlService": "International service",
	"orderStatus": "Order status",
	"orderSize": "Order size",
	"buyExtraInsurce": "Purchase additional insurance",
	"orderCreateAt": "Order date",
	"parcelQty": "Parcel date",
	"pickStore": "Drop to store",
	"productName": "Product name",
	"productCate": "Product category",
	"shenbaoPrice": "Value declared",
	"shenbaoQty": "Quantity declared",
	"hscode": "HS code",
	"enyterHscode": "Please input HS code",
	"unitWeight": "Unit weight",
	"unitWeightKg": "Unit weight (KG)",
	"enterunitWeightKg": "Please input unit weight (KG)",
	"brand": "Brand",
	"unit": "Specification/ items No.",
	"enterunit": "Please input specification/ items No.",
	"productDetail": "Product details",
	"feeDetail": "Fees breakdown",
	"noOrder": "No order",
	"returnHome": "Return home",
	"currAmount": "Current amount",
	"amountMayChangeWhenSizeChanged": "Please note charges are estimated based on the information provided. Charges may differ should the parcel size and weight be different to that stated.",
	"close": "Close",
	"confirmSave": "Confirm to save",
	"click": "Click",
	"collapse": "Collaspse",
	"parcelDetail": "Parcel details",
	"parcel": "Parcel",
	"chargableWeight": "Chargeable weight",
	"total": "Total",
	"fuelFee": "Fuel fee",
	"addToCart": "Add to cart",
	"queryAddr": "Search",
	"shenbaoExample": "Declaration examples",
	"sameAddrExceedLimit": "Please add new receiver when the number of packages has exceeded the maximum within a same address",
	"enterBrand": "Please input brand",
	"enterGoodsNameCn": "Please input product name(Chinese)",
	"enterGoodsNameCnEn": "Please input product name(Chinese/English)",
	"sameGoodsNoMoreThan3": "The quantity of the same product is limited to 3 pieces",
	"enterQty": "Please input quantity",
	"cate": "Category",
	"selectCate": "Please select category",
	"selectGoodsOrSearch": "Please select an item or input keywords for searching",
	"selectGoods": "Please select items",
	"danjia": "Unit price",
	"enterdanjia": "Please input unit price",
	"shuilv": "Tax rate",
	"tax": "Tax",
	"nowAllowedCate": "Prohibited and restricted goods Watch/Jewelry/Alcohol/ Luxury brand goods/Antiques/Fluid Milk",
	"productNameEn": "Product name(English)",
	"enterDetailChineseName": "Please provide the detailed Chinese names of products",
	"hsCodeOptional": "HS code",
	"lookUp": "Enquiry",
	"shipinDetailNotify": "If you send health care products, please note the specific grams and tablets in the specification section. If you send garment,shoes and bags, specific type and size should be noted",
	"parcelValueNoMoreThan3000": "Maximum parcel value 3000RMB",
	"networkError": "Network error",
	"pickUpType": "Collecting methods",
	"addParcel": "Add parcel",
	"currTax": "Current tax",
	"freeTax50rmb": "Tax credit 50rmb",
	"currTotalVal": "Current total value",
	"goodsTotalTax": "Total goods tax",
	"parcelGoodsDetail": "Consignment summary",
	"parcelWeightandSize": "Parcel weight/size",
	"parcelNote": "Parcel note",
	"insuranceLimit": "Insurance limit",
	"selectInsurance": "Please select insurance service",
	"insuranceService": "Insurance service",
	"deleteParcel": "Delete parcel",
	"weightMustMoreThan01kg": "Minimum weight 0.1kg",
	"heightMustMoreThan1cm": "Minimum height 1cm",
	"widthMustMoreThan1cm": "Minimum width 1cm",
	"lengthMustMoreThan1cm": "Minimum length 1cm",
	"length_cm": "Length (CM)",
	"deleteReceiver": "Delete receiver",
	"exceedMaxGoods": "Maximum declaration quantity exceeded",
	"saveParcelFillAutoNext": "Save the declaration information for next time",
	"enterHaojiName": "Please enter the name of quick declaration plan",
	"quickShenbao": "Customs Declaration",
	"save": "Save",
	"delete": "Delect",
	"schemaUpdated": "Scheme updated",
	"selectShenbao": "Please select the declaration",
	"update": "Update",
	"storeName": "Store name",
	"checkStoreDetail": "Check store details",
	"dropOffAddr": "Drop point address",
	"dropOff": "Drop point",
	"whatservice": "Our services",
	"dropOffInfo": "Drop store details",
	"openTime": "Opening hours",
	"contactTel": "Contact number",
	"selectThisStore": "Select this store",
	"goSofortPay": "Click the button below to go to the Sofort website for payment",
	"goBarclayPay": "Click the button below to go to the Barclay website for payment",
	"unpaid": "Unpaid",
	"generateAlipayQRcode": "Click to get a payment QR code",
	"alipayNotify": "Note Do not leave this page after scanning until payment successful",
	"wechatNotify": "Note Do not leave this page after scanning until payment successful",
	"PayByBalance": "Use the balance to complete the payment",
	"yourBalance": "Your balance",
	"balcenNotEnough": "Insufficient balance",
	"chongzhi": "Top up",
	"cannotFetchPayResult": "Accessing payment results failed",
	"paySuccess": "Payment success",
	"toPayAmount": "Payment amount",
	"yourOrderHasCreated": "Your order has been created",
	"youCanGoTo": "Now you can go to",
	"confirmAndPay": "Confirm and pay",
	"orderList": "Order list",
	"choosePageCheckAndDownload": "Check order detail and download order label",
	"luggageOrderYoucango": "Luggage Order, You can go to",
	"myLuggage": "My Luggage",
	"toDownloadLabel": "Download and print label",
	"orYouCan": "Or",
	"printLabelNotice": "Please make sure the barcode is clear enough when printing the delivery order. Please paste the delivery order on the largest side of the box and send it to the corresponding delivery point or wait for the driver to pick it up",
	"returnHomeText": "Return home",
	"payQuickly": "Order successful. Please pay as soon as possible",
	"payIn24HorCancel": "Please complete the payment within 24 hours, otherwise the order will be cancelled automatically",
	"selectPayWay": "Select a payment method",
	"submitted": "Submitted",
	"product": "Product",
	"jianzhuang": "Packing according to unit",
	"packageCart": "Package cart",
	"goShopping": "Continue browsing",
	"zongji": "Total",
	"mallManjian": "Free shipping available when reach a certain amount",
	"goBackShopping": "Return to continue shopping",
	"okGoCart": "Confirm and check Cart",
	"shoujia": "Price",
	"combine": "Combination",
	"buyNow": "Buy now",
	"tuWenDetail": "Image and text details",
	"lookAndLook": "Browsing",
	"checkDetail": "Check details",
	"goPackageCart": "Enter the package cart",
	"max5Attachments": "Maximum attachments:5",
	"uploadSuccess": "Upload successful",
	"uploadFail": "Upload failed",
	"waitAdminReply": "Waiting for administrator reply",
	"waitClientReply": "Waiting for customer reply",
	"closed": "Closed",
	"noReply": "No reply",
	"cpmNumber": "Order number",
	"title": "Title",
	"relativeNumber": "Associated order number",
	"status": "Status",
	"clinetTag": "Customer tags",
	"adminTag": "Administrator tags",
	"lastReply": "Last reply",
	"createdAt": "Created date",
	"xiangqing": "Details",
	"cpmDesc": "Problem parcel system aims to help customers to give feedback on package problems and follow up after sales",
	"cpmLongDesc": "If there is any unusual conditions such as loss and return, or the outer box is damaged and cannot be delivered after the parcel arrives at Send2China warehouse, we will inform you through this system and you can speak to the warehouse directly.",
	"cpmAllStatus": "All status",
	"cpmStatusCreated": "Created",
	"cpmStatusWait": "Waiting process",
	"cpmStatusProcessed": "Processed",
	"cpmSearch": "Keyword search",
	"basicInfo": "Basic information",
	"wu": "None",
	"attachment": "Attachment",
	"chatLog": "Chatlog",
	"enterYourReply": "Enter your reply",
	"uploadAttachment": "Upload attachment",
	"clickOrDrag": "Please click or drag the file here",
	"width_cm": "width(CM)",
	"height_cm": "height(CM)",
	"size_cm": "size(CM)",
	"enter6digital": "Please enter 6 digits",
	"enter8digital": "Please enter 8 digits",
	"sadOrders": "Customs declaration order",
	"serviceMoreDesc": "Service Description",
	"z4pdxw": "Weight of a single item"
};