module.exports = {
	"weight": "Gewicht",
	"width": "Breite",
	"length": "Länge",
	"height": "Höhe",
	"size": "Maße",
	"destination": "Zielland",
	"correctNumber": "Bitte geben Sie die richtige Nummer ein.",
	"freightEstimate": "Frachtkostenberechnung",
	"reevaluate": "Neubewertung",
	"selectParcelType": "Bitte Pakettype auswählen",
	"fee": "Gebühren",
	"timeliness": "Aktualität",
	"serviceDetail": "Detail",
	"serviceNotAvailable": "nicht verfügbar",
	"selectService": "auswählen",
	"correctSizeWeight": "Bitte geben Sie die richtige Größe und Gewichte ein.",
	"chooseService": "Service auswählen",
	"chooseReturnService": "Retourservice auswählen",
	"fillParcelInfo": "Paketdetails ausfüllen",
	"confirmOrder": "bestätigen",
	"returnPrevious": "Zurück",
	"attention": "Achtung",
	"returnServiceDestIsSend2China": "Das Ziel des Retourservice ist das Lager von Send2China GmbH",
	"minimumQty": "min. Menge",
	"addressNotAvailableForPickup": "Entschuldigung, Ihre Adresse ist nicht verfügbar",
	"noData": "keine Daten",
	"queryingAddressData": "in Bearbeitung",
	"name": "Name",
	"enterName": "Bitte Ihren Namen eingeben",
	"englishOnly": "nur Englisch",
	"phone": "Telefon",
	"enterPhone": "Bitte die Telefonnummer eingeben.",
	"enterCorrectPhone": "Bitte die richtige Telefonnummer eingeben",
	"postcode": "Postleitzahl",
	"enterPostcode": "Bitte PLZ eingeben.",
	"enter5digital": "Bitte 5 Ziffer eingeben",
	"address": "Adresse",
	"enterAddress": "Bitte Adresse eingeben",
	"houseNumber": "Hausnummer",
	"enterHouseNumber": "Bitte Hausnummer eingeben",
	"chineseOnly": "nur Chinesisch",
	"chooseAddress": "Adresse auswählen",
	"concreteStreet": "konkrete Strasse",
	"chineseStreet": "chinesische Straße",
	"enterchineseStreet": "Bitte chinesische Straße eingeben",
	"idNumber": "ID-Nummer",
	"enterIdNumber": "Bitte ID-nummer eingeben",
	"enterCorrectIdNumber": "Bitte richtige ID-nummer eingeben",
	"saveAddressDefault": "speichern als Standard (beim nächsten Mal automatisch ausfüllen)",
	"saveAddressNew": "als neue Adresse hinzufügen",
	"pickService": "Abholungsservice",
	"pickTime": "Abholungszeit",
	"pickDate": "Abholungsdatum",
	"selectpickDate": "Bitte Abholungsdatum auswählen",
	"note": "Hinweis",
	"changeStore": "Filiale wechseln",
	"choosenStore": "Filialt auwählen",
	"continueFillReceiver": "bestätigen und weitere Empfänger ausfüllen",
	"addedCart": "zum Einkaufswagen hinzugefügt",
	"serviceTerm": "allgemeine Geschäftsbedingungen",
	"termConfirmed": "Ich habe gelesen und bestätigt, dass das Paket korrekt ist.",
	"cancel": "stornieren",
	"alreadySetDefaultReturnService": "Sie haben die Standardretourservice festgelegt",
	"clickToModifyReturnService": "Klicken Sie hier, um die Standardretourservice zu ändern",
	"ifNeedModifyReturnServiceGoBack": "Bitte klicken Sie “Zurück” zur letzten Schritt wenn Sie Retourservice ändern möchten",
	"sender": "Absender",
	"senderAndCollect": "Absender /Abholung",
	"submit": "Absenden",
	"loadFromAddressBook": "hochladen aus Adressebuch",
	"edit": "bearbeiten",
	"receiver": "Empfänger",
	"addReceiver": "Empfänger hinzufügen",
	"selectPromoOrder": "Bitte angebotene Bestellung ankreuzen",
	"promoCodeApplyed": "Rabattecode eingelöst",
	"couponApplyed": "Gutschein eingelöst",
	"deleteing": "löschen...",
	"doneDeleteing": "gelöscht",
	"orderDeleted": "Bestellung gelöscht",
	"promoCode": "Rabattecode",
	"enterPromoCode": "Bitte Rabattecode eingeben",
	"use": "verwenden",
	"redpacket": "Gutschein",
	"select": "auswählen",
	"noPacket": "kein Gutschein",
	"coupon": "Gutschein",
	"noCoupon": "kein Gutschein",
	"useYouhui": "Rabattcode/Gutscheine einlösen",
	"handlingOrder": "Nachzahlung",
	"orderNumber": "Auftragsnummer",
	"service": "Service",
	"orderType": "Versendungsarte",
	"claimWeight": "angegebenes Gewicht",
	"realWeight": "(KG) tatsächtliches Gewicht",
	"shuoming": "Erklärung",
	"handlingFee": "Nachzahlungsbetrag",
	"parcelorder": "Versendung",
	"expiredAndClick": "Die Bestellung ist abgelauft. Bitte “Details anzeigen” klicken - > Bearbeiten",
	"weight_kg": "Gewicht",
	"jine": "Betrag",
	"intlfee": "internationale Fracht",
	"returnfee": "nationale Fracht",
	"prepaytax": "Vorsteuer",
	"ranzahlungadditionfee": "zusätzliche Kosten",
	"insurancefee": "Versicherungsprämie",
	"discount": "Rabatt",
	"totalfee": "Gesamtsumme",
	"action": "durchführen",
	"detail": "Bestellung ansehen",
	"confirmDeleteOrder": "Möchten Sie die Bestellung wirklich löschen?",
	"confirmDelete": "Möchten Sie wirklich löschen?",
	"ok": "Ja / Bestätigung",
	"deleteOrder": "Bestellung löschen",
	"returnOrder": "Retourauftrag",
	"expireAndReorder": "Der Auftrag ist abgelaufen. Bitte löschen und neu bestellen",
	"returnService": "Retourservice",
	"quantity": "Bitte Menge eingeben",
	"contact": "Kontakt",
	"pickAddrAndStore": "Abholungsadresse/ Filialeaderesse",
	"mallOrder": "Auftrag von Verpackungsmaterial",
	"productList": "Produkt",
	"recipient": "Empfänger",
	"productfee": "Preis",
	"expressfee": "Versandkosten",
	"amount": "Gesamtsumme",
	"loading": "hochladen",
	"subtotal": "Zwischensumme",
	"selectall": "alles auswählen",
	"returnAndContinueOrder": "zurück zur Homepage",
	"needpay": "zu zahlende Betrag",
	"alreadydiscount": "Angebot",
	"checkout": "zur Kasse gehen",
	"deleteSelected": "Ausgewählte löschen",
	"items": "Artikel",
	"intlService": "internationales Service",
	"orderStatus": "Status",
	"orderSize": "Volumen",
	"buyExtraInsurce": "Zusatzversicherung",
	"orderCreateAt": "Auftragsdatum",
	"parcelQty": "Paketdatum",
	"pickStore": "Selbstversorgen",
	"productName": "Produktname",
	"productCate": "Kategorie",
	"shenbaoPrice": "angegebener Preis",
	"shenbaoQty": "angegebene Menge",
	"hscode": "HScode",
	"enyterHscode": "Bitte HScode eingeben",
	"unitWeight": "Gewichtseinheit",
	"unitWeightKg": "Gewichtseinheit (KG)",
	"enterunitWeightKg": "Bitte Gewichtseinheint (KG) eingeben",
	"brand": "Marke",
	"unit": "Masse/Artikelnummer",
	"enterunit": "Bitte Masse/Artikelnummer eingeben",
	"productDetail": "Produktdetails",
	"feeDetail": "Gebühren im Detail",
	"noOrder": "Kein Auftrag",
	"returnHome": "zurück zur Homepage",
	"currAmount": "aktuelle Summe",
	"amountMayChangeWhenSizeChanged": "Wenn Sie die Größe und das Gewicht des Pakets ändern, wird möglicherweise der Gesamtbetrag neu berechnet",
	"close": "schließen",
	"confirmSave": "Speichern bestätigen",
	"click": "klicken",
	"collapse": "zusammenklappen",
	"parcelDetail": "Paketdetails",
	"parcel": "Paket",
	"chargableWeight": "kostenpflichtiges Gewicht",
	"total": "gesamt",
	"fuelFee": "Fuel fee",
	"addToCart": "zur Einkaufswagen hinzufügen",
	"queryAddr": "Adresse abfragen",
	"shenbaoExample": "Beispiel",
	"sameAddrExceedLimit": "同一地址，超过最大包裹数限制，请增加新收件人！",
	"enterBrand": "Bitte Marke eingeben",
	"enterGoodsNameCn": "Bitte Produktname (chinesich) eingeben",
	"enterGoodsNameCnEn": "Bitte Produktname (chinesich/englisch) eingeben",
	"sameGoodsNoMoreThan3": "Die gleiche Artikel dürfen 3 nicht überschreiten",
	"enterQty": "Bitte Menge eingeben",
	"cate": "Kategorie",
	"selectCate": "Kategorie auswählen",
	"selectGoodsOrSearch": "Bitte Produkt auswählen oder Stichwort eingeben",
	"selectGoods": "Bitte Produkt auswählen",
	"danjia": "Einzelpreis",
	"enterdanjia": "Bitte Einzelpreis eingeben",
	"shuilv": "Steuersatz",
	"tax": "Steuer",
	"nowAllowedCate": "Der Versand von Uhren / Schmuck / Alkohol / Luxusmarkenprodukte / Antiquitäten / Flüssigmilch ist nicht gestattet",
	"productNameEn": "Produktname (Englisch)",
	"enterDetailChineseName": "Bitte den Produktnamen in chinesich eingeben",
	"hsCodeOptional": "HScode (wahlweise)",
	"lookUp": "Abfrage",
	"shipinDetailNotify": "Wenn Sie Nahrungsergänzungsmittel verschicken, wie viele Gramm oder wie viele Tabletten müssen es geschrieben werden. Wenn Sie Kleidung oder Schuhe verschicken, müssen Sie den spezifischen Stil, die Artikelnummer oder die Größe eingeben.",
	"parcelValueNoMoreThan3000": "Der gesamte Paketwert darf RMB 3.000 nicht überschreiten",
	"networkError": "Netzwerkfehler",
	"pickUpType": "Abholmethode",
	"addParcel": "Paket hinzufügen",
	"currTax": "aktuelle Steuer",
	"freeTax50rmb": "steuerfrei xxx",
	"currTotalVal": "aktueller Wert",
	"goodsTotalTax": "Gesamtsteuer auf Waren",
	"parcelGoodsDetail": "Produkte im Detail",
	"parcelWeightandSize": "Paketgewicht/Maße",
	"parcelNote": "包裹备注（仅个人可见）",
	"insuranceLimit": "Versicherungslimit",
	"selectInsurance": "Versicherung auswählen",
	"insuranceService": "Versicherungsservice",
	"deleteParcel": "Paket stornieren",
	"weightMustMoreThan01kg": "Gewicht minestens größer als 0,1kg",
	"heightMustMoreThan1cm": "mindestens höher als 1cm",
	"widthMustMoreThan1cm": "mindestens bereiter als 1cm",
	"lengthMustMoreThan1cm": "mindestens länger als 1cm",
	"length_cm": "Länger(CM)",
	"deleteReceiver": "Empfänger löschen",
	"exceedMaxGoods": "Die maximal meldepflichtige Menge wurde überschritten",
	"saveParcelFillAutoNext": "Speichern Sie die Angabe für die nächste schnelle Ausfüllung",
	"enterHaojiName": "Geben Sie bitte einen xxx Name ein",
	"quickShenbao": "schnelle Deklaration",
	"save": "speichern",
	"delete": "löschen",
	"schemaUpdated": "Die Lösung wurde aktualisiert",
	"selectShenbao": "Bitte die auszufüllende Erklärung auswählen",
	"update": "aktualisieren",
	"storeName": "Filialename",
	"checkStoreDetail": "Filialeinfo ansehen",
	"dropOffAddr": "die Adresse der Übergabestelle",
	"dropOff": "Übergabestelle",
	"whatservice": "angebotene Service",
	"dropOffInfo": "Info zur Übergabestelle",
	"openTime": "Öffnungszeit",
	"contactTel": "Telefon",
	"selectThisStore": "diese Filiale auswählen",
	"goSofortPay": "auf die Schaltfläche unten klicken zur Zahlung per Sofort",
	"goBarclayPay": "auf die Schaltfläche unten klicken zur Zahlung per Barclay",
	"unpaid": "unbezahlt",
	"generateAlipayQRcode": "klichen zur Generation der Alipay-QRcode",
	"alipayNotify": "Hinweis Bitte diese Seite nach dem Scannen der Code-Zahlung nicht schließen. Warten Sie, bis auf der Seite angezeigt wird, dass die Zahlung abgeschlossen ist, und dann schließen Sie sie diese Seite!",
	"wechatNotify": "Hinweis Bitte diese Seite nach dem Scannen der Code-Zahlung nicht schließen. Warten Sie, bis auf der Seite angezeigt wird, dass die Zahlung abgeschlossen ist, und dann schließen Sie sie diese Seite!",
	"PayByBalance": "Zahlung per Guthaben",
	"yourBalance": "Ihre Guthaben",
	"balcenNotEnough": "Guthaben unzureichend, bitte zuerst",
	"chongzhi": "anzahlen",
	"cannotFetchPayResult": "Zahlungsergebniss konnte nicht abgerufen werden",
	"paySuccess": "Zahlung erfolgt",
	"toPayAmount": "zahlende Betrag",
	"yourOrderHasCreated": "！Ihre Auftrag wurde erstellt!",
	"youCanGoTo": "Sie können jetzt zur xxx",
	"confirmAndPay": "betätigen und zur Kasse gehen",
	"orderList": "Auftraglist",
	"toDownloadLabel": "unterladen und drucken",
	"orYouCan": "oder Sie können",
	"printLabelNotice": "Der Barcode muss scharf gedruckt werden. Bitte legen Sie das Deckblatt auf die größte Seite des Pakets und senden Sie es an die entsprechende Zustellstelle oder warten Sie auf unsere Fahrer zur Abholung, je nach dem bei der Bestellung ausgewählten Zustelldienst",
	"returnHomeText": "zurück zur Homepage",
	"payQuickly": "Der Bestellvorgang ist erfolgreich, bitte zahlen Sie so schnell wie möglich!",
	"payIn24HorCancel": "Bitte vervollständigen Sie die Zahlung innerhalb von 24 Stunden, ansonsten wird die Bestellung automatisch storniert.",
	"selectPayWay": "Zahlungsmittel auswählen",
	"submitted": "eingereicht",
	"product": "Produkt",
	"jianzhuang": "stückweise verpacken",
	"packageCart": "Einkaufswagen der Verpackungsmaterial",
	"goShopping": "weiter einkaufen",
	"zongji": "gesamt",
	"mallManjian": "ab xxx EUR wird Verpackungsmaterial kostenfrei versandt",
	"goBackShopping": "zurück zum weiteren Einkaufen",
	"okGoCart": "bestätigen, zum Einkaufswagen",
	"shoujia": "Preis",
	"combine": "组合",
	"buyNow": "sofort einkaufen",
	"tuWenDetail": "Bild im Detail",
	"lookAndLook": "看了又看",
	"checkDetail": "Details ansehen",
	"goPackageCart": "zum Einkaufswagen des Verpackungsmaterials",
	"max5Attachments": "max. 5 Anhänge hochladen",
	"uploadSuccess": "Hochladen erfolgt",
	"uploadFail": "Hochladen nicht erfolgt",
	"waitAdminReply": "Antwort für den Kunden",
	"waitClientReply": "Antwort von dem Kunden",
	"closed": "geschlossen",
	"noReply": "keine Antwort",
	"cpmNumber": "Anfragenummer",
	"title": "Titel",
	"relativeNumber": "zugehörige Auftragsnummer",
	"status": "Status",
	"clinetTag": "Kunden xxx",
	"adminTag": "Administrator  xxx",
	"lastReply": "letzte Antwort",
	"createdAt": "Erstellungsdatum",
	"xiangqing": "Einzelheit",
	"cpmDesc": "Das CPM-System ist ein Kundendienstsystem, das Kunden bei der Durchführung von Rückmeldungen zu Paketproblemen und der Nachverfolgung  unterstützt.",
	"cpmLongDesc": "Wenn Ihr Paket einen außergewöhnlichStatus aufweist, müssen Sie eine Rückmeldungsanfrage stellen, z. B. keine Nachverfolgung des Pakets, keine Erneuerung der Logistik über einen längeren Zeitraum, Beschädigung / Verlust des Pakets, Rücksendung usw.Oder das Paket weist nach dem Eintreffen im Lager von China  andere ungewöhnliche Zustände auf. Wenn das Paket  beschädigt ist oder nicht versendet werden kann, werden Sie über dieses System benachrichtigt, und Sie können direkt Antwort geben.",
	"cpmAllStatus": "AllStatus",
	"cpmStatusCreated": "Status erstellt",
	"cpmStatusWait": "In Bearbeitung",
	"cpmStatusProcessed": "Verarbeitet",
	"cpmSearch": "Suchbegriff eingeben",
	"basicInfo": "Grund Informationen",
	"wu": "无",
	"attachment": "Anlage",
	"chatLog": "Kommunikationsaufzeichnung",
	"enterYourReply": "Geben Sie hier Ihre Antwort ein",
	"uploadAttachment": "Anhang hochladen",
	"clickOrDrag": "Klicken oder ziehen Sie die Datei hierher",
	"width_cm": "Breit",
	"height_cm": "Hoch",
	"size_cm": "Size",
	"enter6digital": "Bitte geben Sie  6 Ziffern ein",
	"sadOrders": "Customs declaration order",
	"z4pdxw": "Gewicht eines einzelnen Artikels"
};