import { get, post, del, cache } from './http'

const api = {
  // 登录接口
  login: post('/core/login/'),
  logout: post('/core/logout/'),
  phoneLogin: post('/core/phone_login/'),
  register: post('/core/register/'),
  forget: post('/core/users/reset_pwd/'),
  perCheck: post('/core/register/per_check/'),
  checkPhone: post('/core/register/check_phone/'),
  // 发送验证码
  sendSMS: post('/core/send_sms/'),

  // 用户接口
  fetchUserDetail: get('/core/users/detail/'),
  updateUserDetail: post('/core/users/update_info/'),
  fetchUserInvoiceInfo: get('/core/users/invoice/'),
  updateUserInvoiceInfo: post('/core/users/invoice/'),
  fetchMessage: get('/cpm/message/'),
  fetchMessageDetail: get('/cpm/message/detail/'),
  rechargeUser: post('/core/users/recharge/'),
  fetchUserSettings: get('/core/users/settings/'),
  saveUserSettings: post('/core/users/settings/'),
  updateUserInfoAPI: post('/wxapi/user/info/update/'),

  // 订单
  fetchOrderServices: post('/core/intlservices/'),
  fetchOrderServicesExtra: get('/core/intlservices_extra/'),
  orderService: get('/core/intlservices/'),
  fetchOrderReturnServices: get('/core/returnservices/'),
  fetchServiceGoods: get('/core/service_goods/'),
  fetchDeclareGoods: get('/core/goods_declare/'),
  fetchDeclareGoodDetail: get('/core/goods_declare_detail/'),
  fetchDeclareGoodsCategory: get('/core/goods_declare_category/'),
  createDeclareGoods: post('/core/goods_declare/'),
  fetchInsurances: get('/core/serviceinsurances/'),
  fetchAdditional: get('/core/service_additional/'),
  ordering: post('/core/ordering/'),
  fetchCart: get('/core/cart/'),
  fetchImport_order: get('core/import_ordering/'),
  createImportOrder: post('/core/create_import_order/'),
  removeImportOrdering: post('/core/remove_import_ordering/'),
  parcelorder: get('/core/orders/parcelorder/'),
  mergeParcelPdf: post('/core/orders/parcelorder/merge_pdf/'),
  pickupHK: post('/core/hk/pickup-apply/'),
  qrcodeHK: get('/core/hk/qrcode/'),
  pickupHKcancel: post('/core/hk/pickup-cancel/'),
  exporteParcelExcel: post('/core/orders/parcelorder/export_excel/'),
  updateParcelPrinted: post('/core/orders/parcelorder/update_print/'),
  returnorder: get('/core/orders/returnorder/'),
  handlingorder: get('/core/orders/handlingorder/'),
  cancelorder: post('/core/orders/cancel/'),
  copyorder: post('/core/orders/copy/'),
  saveCart: post('/core/cart/'),
  delParcelOrder: del('/core/orders/parcelorder/'),
  delReturnlOrder: del('/core/orders/returnorder/'),
  payByBalance: post('/core/pay/balance/'),
  fetchBillById: get('/core/billing/detail/'),
  checkBillById: post('/core/billing/status/'),
  saveReturnOrder: post('/core/returnordering/'),
  updateParcelorder: post('/core/orders/parcelorder/update/'),
  batchDelOrder: post('/core/orders/batchdelete/'),
  fetchPickupDate: get('/core/available/pickupdate/'),
  validParcelContent: post('/core/parcel/content/validate/'),
  orderEditablefields: post('/core/orders/parcelorder/editablefields/'),
  fetchGoodsDetailById: post('/core/service_goods_by_ids/'),
  fetchServicesReadme: get('/core/servicesreadme/'),
  fetchQuickparcel: get('/core/users/quickparcel/'),
  updateQuickparcel: post('/core/users/quickparcel/'),
  fetchLuggageSetting: get('/core/orders/luggagesetting/'),
  createLuggageOrders: post('/core/orders/luggageorder/'),
  getLuggageOrders: get('/core/orders/luggageorder/'),
  delLuggageOrder: del('/core/orders/luggageorder/'),
  luggageSendBack: post('/core/orders/luggage_sendback/'),

  // 优惠
  fetchRedPacket: get('/marketing/red_envelope/'),
  //获取可领取的优惠券
  fetchCouponList: get('marketing/coupon/'),
  receiveCoupon: post('marketing/coupon/'),
  //获取已经领取的优惠券
  fetchCoupon: get('/marketing/coupon/detail/'),
  checkPromo: post('/core/cart_discount/'),
  reset_discount: post('/core/cart_discount/reset/'),
  fetchAffiliate: get('/marketing/affiliate/'),
  fetchAffiliateLog: get('/marketing/affiliate_log/'),
  convertAffiliateLog: post('/marketing/affiliate_log/'),
  fetchPromotionEvent: get('/marketing/promo_event/'),
  joinPromotionEvent: post('/marketing/promo_event/'),
  fetchMaterialBalance: get('/marketing/material_balance/'),
  converMaterialBalance: post('/marketing/material_balance/'),
  affiliateApply: post('/marketing/affiliate_apply/'),
  affiliateCashLog: get('/marketing/affiliate_cash_log/'),
  fetchAffPendingCommission: get('/marketing/affiliate_pending_commission/'),

  // 地址
  fetchAddress: get('/address/addressbook/'),
  fetchAddressDetail: get('/address/addressbook/detail/'),
  saveAddress: post('/address/addressbook/'),
  delAddress: del('/address/addressbook/'),
  fetchAddressProvince: cache(get('/address/province/list/')),
  fetchAddressCity: cache(get('/address/city/list/')),
  fetchAddressCounty: cache(get('/address/county/list/')),

  // 门店
  fetchStoreList: get('/store/list/'),

  //商品

  //包材商城
  fetchMallCategories: get('mall/categories/'),
  fetchMallProducts: get('mall/products/'),
  fetchRelatedProducts: get('mall/related_products/'),
  fetchMallOrders: get('mall/orders/'),
  delMallOrder: del('/core/orders/mallorder/'),
  fetchMallBanner: get('mall/banner/'),
  fetchMallCartItems: get('mall/cart/'),
  CartAction: post('mall/cart/'),
  CartSubmit: post('mall/cart/submit/'),

  // 代发商城
  fetchShopOrders: get('shop/orders/'),
  delShopOrder: del('shop/orders/'),

  //工单
  fetchCpmList: get('/cpm/list/'),
  saveCpm: post('/cpm/list/'),
  saveCpmReply: post('/cpm/detail/'),
  fetchCpmDetail: get('/cpm/detail/'),
  fetchCpmTagList: get('/cpm/tag/list/'),

  //账单
  fetchBillingList: get('/billing/billing/'),
  fetchInvoiceList: get('/billing/invoice/'),
  mergeInvoicePdf: post('/billing/invoice/merge_pdf/'),

  //支付
  alipay_pay: post('/fusionpay/alipay/payinfo_v2/'),
  sofort_pay: post('/sofort/deposit/'),
  barclay_pay: post('/barclay/deposit/'),
  deposit: post('/core/deposit/'),

  //cms
  login_banner: get('/cms/login_banner/'),
  account_home_news: get('/cms/account_home_news/'),
  account_home_help: get('/cms/account_home_helps/'),

  // banners
  fetchBanners: get('/cms/banners/'),
  isMilkUser: get('/cms/milkuser/'),

  // 申报系统
  fetchSadOrders: get('/sad/orders/'),
  fetchParcelorders: get('/sad/parcelorders/'),
  fetchParcelorderDetail: (id: number) => get(`/sad/parcelorder/${id}/`),
  updateParcelorderDetail: (id: number) => post(`/sad/parcelorder/${id}/`),
  cancelSadOrders: (id: number) => post(`/sad/parcelorder/${id}/cancel/`),

  //转运
  fetchZhuanyunYubao: get('/zhuanyun/yubao/'),
  saveZhuanyunYubao: post('/zhuanyun/yubao/'),
  fetchExpressCompay: get('/zhuanyun/express_company/'),
  createZhuanyunOrder: post('/zhuanyun/order/'),
  delZhuanyunOrder: del('/zhuanyun/order/'),
  createZhuanyunMessage: post('/zhuanyun/order/message/'),
  fetchZhuanyunOrders: get('/zhuanyun/order/'),
  fetchZhuanyunInsurance: get('/zhuanyun/insurance/'),
}

export default api
