import { observable } from 'mobx'

import api from '../api'

export default class Zhuanyun {
  root: any

  constructor(root) {
    this.root = root
  }

  @observable
  detail: any = {}

  @observable
  order_detail: any = {}

  @observable
  zhuanyuns: any = {
    data: [],
    paginator: {},
  }

  @observable
  unpack_orders: any = {
    data: [],
    paginator: {},
  }
  @observable
  pack_orders: any = {
    data: [],
    paginator: {},
  }

  async fetchDetail(params: { code: string }) {
    const res = await api.fetchZhuanyunYubao(params)
    this.detail = res
    return res
  }

  async fetchZhuanyuns(params?: {
    page?: number
    per_page?: number
    start_date?: string
    end_date?: string
    q?: string
  }) {
    params.per_page = params.per_page || 50 // 默认显示多一些
    const res = await api.fetchZhuanyunYubao(params)
    this.zhuanyuns = res
    return res
  }
  async fetchUnpackOrder(params?: {
    page?: number
    perpage?: number
    start_date?: string
    end_date?: string
    q?: string
  }) {
    params['pack_status'] = 1
    const res = await api.fetchZhuanyunOrders(params)
    this.unpack_orders = res
    return res
  }
  async fetchpackOrder(params?: {
    page?: number
    perpage?: number
    start_date?: string
    end_date?: string
    q?: string
  }) {
    params['pack_status_done'] = 'Y'
    const res = await api.fetchZhuanyunOrders(params)
    this.pack_orders = res
    return res
  }

  async fetchOrderDetail(params: { id: string }) {
    const res = await api.fetchZhuanyunOrders(params)
    this.order_detail = res
    return res
  }
  async JoinEvent(event_id) {
    const res = await api.joinPromotionEvent({ event_id: event_id })
    return res
  }
}
