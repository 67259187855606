import * as React from 'react'
import loadable from 'loadable-components'
import { Redirect } from 'react-router-dom'

export default {
  path: '/accountCenter',
  component: loadable(() => import('../views/AccountCenter')),
  meta: {
    isPrivate: true,
    navs: [
      {
        title: i18n.t('account:accountInformation'),
        id: 0,
        icon: require('../views/AccountCenter/images/nav-1.png'),
      },
      {
        title: i18n.t('account:myOrders'),
        id: 5,
        icon: require('../views/AccountCenter/images/nav-1.png'),
      },
      {
        title: i18n.t('account:bussinessAccount'),
        id: 4,
        icon: require('../views/AccountCenter/images/nav-1.png'),
      },
      {
        title: i18n.t('account:myLuggage'),
        id: 3,
        icon: require('../views/AccountCenter/images/nav-4.png'),
      },
      {
        title: '转运包裹',
        id: 6,
        icon: require('../views/AccountCenter/images/nav-4.png'),
      },
      {
        title: i18n.t('account:discount'),
        id: 1,
        icon: require('../views/AccountCenter/images/nav-3.png'),
      },
      {
        title: i18n.t('account:others'),
        id: 2,
        icon: require('../views/AccountCenter/images/nav-2.png'),
      },
    ],
  },
  routes: [
    {
      id: 1,
      path: '/accountCenter/Home',
      component: loadable(() => import('../views/AccountCenter/Home')),
      meta: { isPrivate: true },
    },
    {
      id: 2,
      path: '/accountCenter/orders',
      component: loadable(() => import('../views/AccountCenter/Orders')),
      meta: { isPrivate: true, pid: 5, title: i18n.t('account:orders') },
    },
    {
      id: 14,
      path: '/accountCenter/labels',
      component: loadable(() => import('../views/AccountCenter/Label')),
      meta: { isPrivate: true, pid: 5, title: i18n.t('account:lables') },
    },
    {
      path: '/accountCenter/shopOrders',
      component: loadable(() => import('../views/AccountCenter/ShopOrders')),
      meta: { isPrivate: false, pid: 5, title: i18n.t('account:shopOrders') },
    },

    {
      id: 3,
      path: '/accountCenter/billing',
      component: loadable(() => import('../views/AccountCenter/Billing')),
      meta: { isPrivate: true, pid: 0, title: i18n.t('account:accountBalance') },
    },
    {
      id: 4,
      path: '/accountCenter/updateUserInfo',
      component: loadable(() => import('../views/AccountCenter/UpdateUserInfo')),
      meta: { isPrivate: true, pid: 0, title: i18n.t('account:myLoginDetails') },
    },
    {
      id: 5,
      path: '/accountCenter/AffiliateInfo',
      component: loadable(() => import('../views/AccountCenter/AffiliateInfo')),
      meta: { isPrivate: true, pid: 1, title: i18n.t('account:myRewards') },
    },
    {
      id: 6,
      path: '/accountCenter/Coupon',
      component: loadable(() => import('../views/AccountCenter/Coupon')),
      meta: { isPrivate: true, pid: 1, title: i18n.t('account:myCoupon') },
    },
    {
      id: 7,
      path: '/accountCenter/Redpacket',
      component: loadable(() => import('../views/AccountCenter/Redpacket')),
      meta: { isPrivate: true, pid: 1, title: i18n.t('account:giftMoney') },
    },
    {
      id: 8,
      path: '/accountCenter/goodsDeclare',
      component: loadable(() => import('../views/AccountCenter/GoodsDeclare')),
      meta: { isPrivate: true, pid: 2, title: i18n.t('account:declaration') },
    },
    {
      id: 9,
      path: '/accountCenter/address',
      component: loadable(() => import('../views/AccountCenter/Address')),
      meta: { isPrivate: true, pid: 2, title: i18n.t('account:addressBook') },
    },
    {
      path: '/accountCenter/bis-company-info',
      component: loadable(() => import('../views/AccountCenter/bisUser/CompanyInfo')),
      meta: { isPrivate: false, pid: 4, title: i18n.t('account:companyInformation') },
    },
    {
      path: '/accountCenter/bis-invoice',
      component: loadable(() => import('../views/AccountCenter/bisUser/Invoice')),
      meta: { isPrivate: false, pid: 4, title: i18n.t('account:downloadInvoiceFaPiao') },
    },
    /*
    {
      path: '/accountCenter/bis-clearance',
      component: loadable(() => import('../views/AccountCenter/bisUser/BisClearance')),
      meta: { isPrivate: false, pid: 4, title: i18n.t('account:commercialDeclaration') },
    },
    */
    {
      id: 11,
      path: '/accountCenter/message',
      component: loadable(() => import('../views/AccountCenter/Message')),
      meta: { isPrivate: true, pid: 2, title: i18n.t('account:siteMessage') },
    },
    {
      id: 12,
      path: '/accountCenter/MaterialBalance',
      component: loadable(() => import('../views/AccountCenter/MaterialBalance')),
      meta: { isPrivate: true },
    },
    {
      path: '/accountCenter/AffiliateInfo',
      component: loadable(() => import('../views/AccountCenter/AffiliateInfo')),
      meta: { isPrivate: true },
    },
    {
      id: 13,
      path: '/accountCenter/settings',
      component: loadable(() => import('../views/AccountCenter/Settings')),
      meta: { isPrivate: true, pid: 2, title: i18n.t('account:preferenceSetting') },
    },
    {
      id: 14,
      path: '/accountCenter/luggage',
      component: loadable(() => import('../views/AccountCenter/Luggage')),
      meta: { isPrivate: true, pid: 3, title: i18n.t('account:luggageInformation') },
    },
    {
      id: 14,
      path: '/accountCenter/luggageSendBack',
      component: loadable(() => import('../views/AccountCenter/LuggageSendback')),
    },
    {
      id: 15,
      path: '/accountCenter/zhuanyun',
      component: loadable(() => import('../views/AccountCenter/Zhuanyun')),
      meta: { isPrivate: true, pid: 6, title: '转运订单' },
    },
    {
      path: '*',
      component: () => <Redirect to="/accountCenter/Home" />,
    },
  ],
}
