module.exports = {
	"accountInformation": "账户信息",
	"orders": "订单查询",
	"lables": "面单管理",
	"accountBalance": "账户余额",
	"myLoginDetails": "用户信息",
	"bussinessAccount": "商业用户",
	"companyInformation": "公司信息",
	"downloadInvoice": "发票下载",
	"commercialDeclaration": "商业报关",
	"myLuggage": "我的寄存",
	"luggageInformation": "入库行李",
	"discount": "优惠信息",
	"myRewards": "我的返利",
	"myCoupon": "我的卡券",
	"giftMoney": "我的红包",
	"others": "常用工具",
	"declaration": "物品申报",
	"addressBook": "地址簿",
	"siteMessage": "站内信",
	"preferenceSetting": "偏好设置",
	"internationalParcels": "国际订单",
	"ukParcels": "内陆订单",
	"balanceToPay": "补款订单",
	"packagingOrder": "包材订单",
	"collectionInHongkong": "香港自提",
	"shopOrders": "Bestellung bestellen"
};