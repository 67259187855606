module.exports = {
	"email": "邮箱",
	"password": "密码",
	"login": "登录",
	"pleaseInputYourPhone": "请输入您的手机号",
	"pleaseInputYourUsername": "请输入您的邮箱",
	"pleaseInputYourPassword": "请输入您的密码",
	"loginByAccount": "账号登录",
	"loginByPhone": "手机登录",
	"remember": "记住我",
	"notRegistered": "尚未注册",
	"registerNow": "马上注册",
	"forgetPassword": "忘记密码",
	"changePassword": "修改密码",
	"confirm": "确认修改",
	"fillInYourInfo": "填写注册信息",
	"completeInYourInfo": "完善个人信息",
	"agreeTerm": "我已阅读并同意服务条款",
	"nextStep": "下一步",
	"previousStep": "上一步",
	"haveAccount": "已注册",
	"enterVerificationCode": "请输入验证码",
	"countdown": "倒计时",
	"getVerificationCode": "获取验证码",
	"usernamne": "用户名",
	"enterCorrectUsername": "请输入小写英文、数字、下划线",
	"confirmPassword": "确认密码",
	"usernameOrEmail": "用户名或邮箱",
	"enterCorrectPhone": "请输入不以0开头的手机号",
	"germanPostcode": "德国邮编",
	"enterPostcode": "请输入邮编",
	"enterState": "请输入state",
	"onlyDigital": "请输入数字",
	"ukPostcode": "英国邮编",
	"pleaseenterukPostcode": "请输入英国邮编",
	"onlyazand09": "只能输入字母或数字",
	"city": "城市",
	"enterCity": "请输入城市",
	"codeSent": "验证码已通过短信发送到您的手机",
	"noZeroforPhone": "手机号无需输入0",
	"passwordNotSame": "密码不一致",
	"usernameOptional": "用户名(可选)"
};